@use 'sass:map';
@use 'sass:string';

@mixin titleText($color: $FRNGrey) {
  text-transform: uppercase;
  color: $color;
}

//make this h1?
@mixin pageTitle {
  @include titleText;
  font-size: $font-size-regular;
  font-weight: 400;
}

//h3
@mixin contentTitle {
  font-size: 14px;
  @include titleText;
}

//h5
@mixin subTitle {
  font-size: 18px;
  @include titleText;
}

@mixin noPadding {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@mixin dot($width: $dotWidth, $height: $dotHeight, $radius: $dotRadius, $bgColor: $dotBgColor, $color: $dotColor) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bgColor;
  color: $color;
}

//for reference only (bootstrap variables):
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;

$default-breakpoint: sm !default;

@mixin bp($size: string.unquote($default-breakpoint), $breakpoints: $grid-breakpoints) {
  @media (min-width: map.get($breakpoints, $size)) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}

//responsive indent on < sm
@mixin addPadding {
  margin-left: $generic-left-margin;
  margin-right: $generic-left-margin;

  @include bp(sm) {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin addTrailingMargin($factor: 1) {
  margin-bottom: calc($consistent-trailing-bottom-margin / $factor) !important;
}

@mixin addLeadingMargin($factor: 1) {
  margin-top: calc($consistent-leading-top-margin / $factor);
}

@mixin profileButton {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  @include font-light;
  box-shadow: $main-three-way-box-shadow;
}
// This is used in WLCVideo first, also AuthorCard
@mixin tagsBox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  margin-top: 4px;
  > div {
    margin-right: 5px;
    margin-top: 4px;
  }
}

@mixin whiteBoxWithShadow {
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);

  @include media-breakpoint-down(xs) {
    margin-right: calc(-1 * $grid-gutter-width/2);
    margin-left: calc(-1 * $grid-gutter-width/2);
    width: calc(100% + 30px);
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

// Bookmark and favorites - narrow and wide versions use this
@mixin interact {
  .interact {
    cursor: pointer;
    margin-bottom: 8px;
    &.interactLike {
      display: flex;
      .interactLiked {
        width: 26px;
        height: 24px;
        margin-right: 16px;
        background: hotpink;
      }
      .interactNotLiked {
        width: 26px;
        height: 24px;
        margin-right: 16px;
        background: url('../images/heart/heart-large.png') 0 0px no-repeat;
      }
    }

    &.interactBookmark {
      display: flex;
      .interactBookmarked {
        width: 26px;
        height: 24px;
        margin-right: 16px;
        background: hotpink;
      }
      .interactNotBookmarked {
        width: 26px;
        height: 24px;
        margin-right: 16px;
        background: green;
      }
    }
    span {
      font-size: 16px;
    }

    &.interactPrint {
      display: flex;
      padding-right: 12px;
      // Print icon here
      > div {
        width: 26px;
        height: 24px;
        margin-right: 16px;
        background: green;
      }
    }
  }
}
