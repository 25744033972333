.audioContainer {
  margin-bottom: 48px !important;

  @include addTrailingMargin;
  audio {
    display: block;
  }
  &:last-of-type {
    @include addTrailingMargin(0.33);
  }
  @include addPadding;

  @include media-breakpoint-down(xs) {
    margin-left: 0;
    margin-right: 0;
    .card-body {
      padding: calc($grid-gutter-width/2);
    }
  }
}
.apmDownloadsListWide {
  a {
    color: $text-color-body-dark;
  }
  span {
    font-size: $font-size-small;
    text-transform: uppercase;
  }
}

.apmPictureV2 {
  // max-height:150px;
  // overflow: hidden;

  img {
    width: 100%;
  }
}
