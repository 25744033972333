.resourcesListContainer {
  @include noPadding;
}

.resourcesListTitle {
  @include contentTitle;
  @include addTrailingMargin;
  font-size: 12px;
  font-weight: small;
  @include addPadding;
}

.resourcesListGridContainer {
  > div {
    [class='customBorder'] {
      border-bottom: 1px solid $white;
    }
    // border-bottom: 1px solid green !important;
    &:nth-child(odd) {
      border-right: 1px solid $white;
    }

    &:nth-last-child(2) {
      [class='customBorder'] {
        border-bottom: none !important;
      }
    }

    &:last-child {
      [class='customBorder'] {
        border-bottom: none !important;
      }
    }
  }
}
