.bonusesListItem {
  color: $FRNGrey;
  border: none;
  margin-bottom: $consistent-trailing-bottom-margin;
  img {
    width: 100%;
    height: auto;
  }

  h3 {
    @include addLeadingMargin(2);
    @include contentTitle;
    max-width: 66%;
    text-transform: none;
    @include addPadding;
  }

  > span {
    @include addTrailingMargin;
    @include addPadding;
  }

  .bonusesListItemDetails {
    @include addTrailingMargin(1.25);
    @include addLeadingMargin(1.25);
    @include addPadding;
  }

  > div {
    margin-bottom: $consistent-leading-top-margin;
    @include addPadding;
    a {
      color: $FRNGrey;
      &:hover {
        text-decoration: none;
      }
      .bonusesListItemLearnMore {
        @include addTrailingMargin(1.25);
      }
      > div {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        > span {
          span {
            padding-right: $generic-left-margin;
            // padding-right: 24px;
          }
        }
      }
    }
  }
}
