.title {
  @include pageTitle;
  @include addLeadingMargin;
  @include addTrailingMargin;
}

.subTitle {
  @include subTitle;
  @include addTrailingMargin(0.5);
  text-transform: none;
}

.list {
  @include addTrailingMargin;
  @include addPadding;
}
