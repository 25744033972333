.courseContainer {
  border: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0;
  @include addTrailingMargin;
}

.courseDescription {
  @include addTrailingMargin;
  @include addPadding;
}

.courseHackySpacing {
  width: 100%;
  @include addTrailingMargin(0.33);
}

.courseTitle {
  @include pageTitle;
  @include addPadding;
}
