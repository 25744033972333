@use "sass:color";
.grey-background {
  background-color: none;
  @include bp(md) {
    background-color: #FAFAFA;
  }
}
.pointerGlobal{
  cursor: pointer !important;
}
.heading-5 {
  font-weight: $font-weight-extrabold;
  font-size:2.0rem;

}
.table-middle-align{
  td, th{ vertical-align: middle;}
}
@include bp(md) {
  body.oval-bg{
    background-attachment: fixed;
    background-image: url("data:image/svg+xml,%3Csvg width='1594' height='740' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='797' cy='370' rx='797' ry='370' fill='%23EAF4F2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: calc( -51.530740276vw + 500px ); // oval_height / oval_width * 100vw * background-size + offset_from_top
    background-size: 111%;
  }
}
@include print {
  * {
    color: black;
    background: none;
    border: none;
  }

  body{
    background: #FFF;
  }
}

// countdown timers
.countdown {

  &-timer {
    margin-top: 1em;
    @include media-breakpoint-up(md) { margin-top: 0; }
  }

  &-element {
    display: inline-block;
    text-align: center;
  }

  &-digit {
    background-color: $FRNPurple;
    border-radius: 6px;
    color: $white;
    font-size: $font-size-h3;
    font-weight: 700;
    margin-right: 6px;
    padding: 10px 6px;
    width: 60px;
    @include media-breakpoint-up(sm) {
      font-size: $font-size-h2;
      width: 80px;
    }
  }

  &-element:last-child {
    .countdown-digit {
      margin-right: 0;
    }
  }

  &-white &-digit{
    background-color: $white;
    color: $black;
  }
}

.img {
  &-responsive{
    display: block;
    width: 100%;
    height: auto;
  }
}
.background-contain{
  background-size: contain !important;
  background-repeat: no-repeat;
}
.skeleton{
  .form-control, label{
    color: transparent;
    background-color: #CFCFCF;
    border: 0;
  }
}

@include media-breakpoint-down(xs) {
  .xs-full-width{
    margin-left: calc(-1*$grid-gutter-width / 2);
    margin-right: calc($grid-gutter-width / 2);
    width: 100vw;
  }
}

.background-white{
  background: #FFF;
}


.me-2{
  margin-right: .5rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ot-sdk-container{
  font-size: 1.75em;
}

.ot-sdk-show-settings {
  border: 0 !important;
  color: $white !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  padding: 0 !important;

  &:hover,
  &:focus {
    background-color: $transparent !important;
    color: color.adjust($white, $alpha: -0.2) !important;
  }
}