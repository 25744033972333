.seperator {
  height: 30px;

  width: 1px;
  background-color: white;
}

.shadowGlobal {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
}

.shadow1 {
  margin: 40px;

  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);

  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)';
  zoom: 1;
}

.zIndex100 {
  z-index: 100 !important;
}

.premieresAt h6 {
}

.float-discalimer {
  cursor: pointer;
}
.float-discalimer:hover {
  text-decoration: underline;
}
