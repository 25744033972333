.ordersOrderBackground {
  background-color: $white-02;
  height: 500vh;
}

.orderViewTitle {
  @include font-light;
  margin: 36px 0;
  span {
    font-size: 24px;
  }
  > div {
    // this is 'back to orders print'
    @include print {
      display: none;
    }
  }
  .orderViewTitleBack {
    span {
      font-size: 12px;
      cursor: pointer;
      color: $FRNGreen;
    }
  }
}

.orderViewPrint {
  @include print {
    display: none;
  }
}

.ordersOrderContainer {
  line-height: 1em;
  margin-bottom: 16px;
  padding: 16px 16px 16px 16px;
  @include print {
    border-bottom: 1px solid black;
    &:last-of-type {
      border-bottom: none;
    }
  }

  .orderViewSummaryRow {
    display: flex;
    justify-content: space-between;

    .orderViewOrderPlaced {
      text-align: left;
      flex-basis: 50% !important;
      order: -10;
      @include bp(sm) {
        flex-basis: unset;
        order: unset;
      }
    }

    .orderViewOrderTotal {
      margin-top: 16px;
      text-align: right;
      order: 30;
      order: 1;
      @include bp(sm) {
        text-align: left;
        margin-top: 0;
        // margin-top: unset;
        // text-align: unset;
        order: 0; // Move me to the end
      }
    }

    .orderViewOrderNum {
      text-align: right;
      flex-basis: 50% !important;
      order: -1;
      @include bp(sm) {
        flex-basis: unset;
        order: unset;
      }
    }

    .orderViewOrderName {
      margin-top: 16px;
    }
  }

  .orderViewBottomBack {
    margin-top: 24px;
    span {
      font-size: 12px;
      cursor: pointer;
      color: $FRNGreen;
    }
  }

  .orderViewNoPrint {
    @include print {
      display: none;
    }
  }
}

.ordersOrderRow {
  cursor: pointer;
  padding: 0 16px;
  margin-bottom: 24px;
  .makeMeGreen {
    color: $FRNGreen;
  }
}
