@charset "UTF-8";
/*
$font-size-h5:                $font-size-base;

$font-size-h4:                ceil(($font-size-base * 1.333));
$font-size-lg-md:             ceil(($font-size-base * 1.555));
$font-size-h3:                ceil(($font-size-base * 1.777));
$font-size-lg-xl:             ceil(($font-size-base * 1.999));
$font-size-h2:                ceil(($font-size-base * 2.369));
$font-size-h1:                ceil(($font-size-base * 3.157));
$font-size-4xl:               ceil(($font-size-base * 3.989));
$font-size-5xl:               ceil(($font-size-base * 4.978));
$font-size-6xl:               ceil(($font-size-base * 5.989));
*/
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://use.typekit.net/ayx6cuo.css");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #7d00b5;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #048d22;
  --secondary: #ff9400;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #222;
  --purple: #7d00b5;
  --blue-gray: #4d6f7b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 34, 34, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.75em;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #048d22;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #024310;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.75em;
  font-weight: 700;
  line-height: 1.1;
  color: #222;
}

h1, .h1 {
  font-size: 4rem;
}

h2, .h2 {
  font-size: 3.2rem;
}

h3, .h3 {
  font-size: 2.8rem;
}

h4, .h4 {
  font-size: 2.4rem;
}

h5, .h5 {
  font-size: 2rem;
}

h6, .h6 {
  font-size: 1.6rem;
}

.lead {
  font-size: 2rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container, .div,
.container-fluid,
.container-2xl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container, .div {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container, .div {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container, .div {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container, .div {
    max-width: 1140px;
  }
}
.container-xl, .container-lg, .container-md, .container-sm, .container, .div {
  max-width: 1700px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(34, 34, 34, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(34, 34, 34, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b9dfc1;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7cc48c;
}

.table-hover .table-primary:hover {
  background-color: #a8d7b2;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a8d7b2;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffe1b8;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ffc77a;
}

.table-hover .table-secondary:hover {
  background-color: #ffd69f;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ffd69f;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c1c1;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8c8c;
}

.table-hover .table-dark:hover {
  background-color: #b4b4b4;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b4b4b4;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #dbb8ea;
}
.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #bb7ad9;
}

.table-hover .table-purple:hover {
  background-color: #d1a4e4;
}
.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #d1a4e4;
}

.table-blue-gray,
.table-blue-gray > th,
.table-blue-gray > td {
  background-color: #cdd7da;
}
.table-blue-gray th,
.table-blue-gray td,
.table-blue-gray thead th,
.table-blue-gray tbody + tbody {
  border-color: #a2b4ba;
}

.table-hover .table-blue-gray:hover {
  background-color: #becbcf;
}
.table-hover .table-blue-gray:hover > td,
.table-hover .table-blue-gray:hover > th {
  background-color: #becbcf;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(34, 34, 34, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(21, 21, 21, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(21, 21, 21, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control, .frn-chat .amplify-tabs .amplify-textfield input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .frn-chat .amplify-tabs .amplify-textfield input {
    transition: none;
  }
}
.form-control::-ms-expand, .frn-chat .amplify-tabs .amplify-textfield input::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus, .frn-chat .amplify-tabs .amplify-textfield input:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #18f849;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.form-control::placeholder, .frn-chat .amplify-tabs .amplify-textfield input::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .frn-chat .amplify-tabs .amplify-textfield input:disabled, .form-control[readonly], .frn-chat .amplify-tabs .amplify-textfield input[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control, .frn-chat .amplify-tabs .amplify-textfield input[type=date],
input[type=time].form-control,
.frn-chat .amplify-tabs .amplify-textfield input[type=time],
input[type=datetime-local].form-control,
.frn-chat .amplify-tabs .amplify-textfield input[type=datetime-local],
input[type=month].form-control,
.frn-chat .amplify-tabs .amplify-textfield input[type=month] {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 2rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .frn-chat .amplify-tabs .amplify-textfield input:valid, .frn-chat .amplify-tabs .amplify-textfield .was-validated input:valid, .form-control.is-valid, .frn-chat .amplify-tabs .amplify-textfield input.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .was-validated .frn-chat .amplify-tabs .amplify-textfield input:valid:focus, .frn-chat .amplify-tabs .amplify-textfield .was-validated input:valid:focus, .form-control.is-valid:focus, .frn-chat .amplify-tabs .amplify-textfield input.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .frn-chat .amplify-tabs .amplify-textfield input:invalid, .frn-chat .amplify-tabs .amplify-textfield .was-validated input:invalid, .form-control.is-invalid, .frn-chat .amplify-tabs .amplify-textfield input.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .was-validated .frn-chat .amplify-tabs .amplify-textfield input:invalid:focus, .frn-chat .amplify-tabs .amplify-textfield .was-validated input:invalid:focus, .form-control.is-invalid:focus, .frn-chat .amplify-tabs .amplify-textfield input.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control, .form-inline .frn-chat .amplify-tabs .amplify-textfield input, .frn-chat .amplify-tabs .amplify-textfield .form-inline input {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .frn-chat .amplify-tabs .amplify-button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .frn-chat .amplify-tabs .amplify-button {
    transition: none;
  }
}
.btn:hover, .frn-chat .amplify-tabs .amplify-button:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .frn-chat .amplify-tabs .amplify-button:focus, .btn.focus, .frn-chat .amplify-tabs .focus.amplify-button {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.btn.disabled, .frn-chat .amplify-tabs .disabled.amplify-button, .btn:disabled, .frn-chat .amplify-tabs .amplify-button:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled), .frn-chat .amplify-tabs .amplify-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, .frn-chat .amplify-tabs a.disabled.amplify-button,
fieldset:disabled a.btn,
fieldset:disabled .frn-chat .amplify-tabs a.amplify-button,
.frn-chat .amplify-tabs fieldset:disabled a.amplify-button {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #048d22;
  border-color: #048d22;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #036819;
  border-color: #035b16;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #036819;
  border-color: #035b16;
  box-shadow: 0 0 0 0.2rem rgba(42, 158, 67, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #048d22;
  border-color: #048d22;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #035b16;
  border-color: #024f13;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 158, 67, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #ff9400;
  border-color: #ff9400;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #d97e00;
  border-color: #cc7600;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #d97e00;
  border-color: #cc7600;
  box-shadow: 0 0 0 0.2rem rgba(255, 164, 38, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #ff9400;
  border-color: #ff9400;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #cc7600;
  border-color: #bf6f00;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 164, 38, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #ffffff;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #222;
  border-color: #222;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #0f0f0f;
  border-color: #090909;
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #222;
  border-color: #222;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #090909;
  border-color: #020202;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 67, 67, 0.5);
}

.btn-purple {
  color: #ffffff;
  background-color: #7d00b5;
  border-color: #7d00b5;
}
.btn-purple:hover {
  color: #ffffff;
  background-color: #63008f;
  border-color: #5a0082;
}
.btn-purple:focus, .btn-purple.focus {
  color: #ffffff;
  background-color: #63008f;
  border-color: #5a0082;
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 192, 0.5);
}
.btn-purple.disabled, .btn-purple:disabled {
  color: #ffffff;
  background-color: #7d00b5;
  border-color: #7d00b5;
}
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show > .btn-purple.dropdown-toggle {
  color: #ffffff;
  background-color: #5a0082;
  border-color: #510075;
}
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 38, 192, 0.5);
}

.btn-blue-gray {
  color: #ffffff;
  background-color: #4d6f7b;
  border-color: #4d6f7b;
}
.btn-blue-gray:hover {
  color: #ffffff;
  background-color: #3e5a63;
  border-color: #39535c;
}
.btn-blue-gray:focus, .btn-blue-gray.focus {
  color: #ffffff;
  background-color: #3e5a63;
  border-color: #39535c;
  box-shadow: 0 0 0 0.2rem rgba(104, 133, 143, 0.5);
}
.btn-blue-gray.disabled, .btn-blue-gray:disabled {
  color: #ffffff;
  background-color: #4d6f7b;
  border-color: #4d6f7b;
}
.btn-blue-gray:not(:disabled):not(.disabled):active, .btn-blue-gray:not(:disabled):not(.disabled).active, .show > .btn-blue-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #39535c;
  border-color: #344c54;
}
.btn-blue-gray:not(:disabled):not(.disabled):active:focus, .btn-blue-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-blue-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 133, 143, 0.5);
}

.btn-outline-primary {
  color: #048d22;
  border-color: #048d22;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #048d22;
  border-color: #048d22;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #048d22;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #048d22;
  border-color: #048d22;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.5);
}

.btn-outline-secondary {
  color: #ff9400;
  border-color: #ff9400;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #ff9400;
  border-color: #ff9400;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 148, 0, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ff9400;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #ff9400;
  border-color: #ff9400;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 148, 0, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #222;
  border-color: #222;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #222;
  border-color: #222;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #222;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #222;
  border-color: #222;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.btn-outline-purple {
  color: #7d00b5;
  border-color: #7d00b5;
}
.btn-outline-purple:hover {
  color: #ffffff;
  background-color: #7d00b5;
  border-color: #7d00b5;
}
.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 0, 181, 0.5);
}
.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #7d00b5;
  background-color: transparent;
}
.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active, .show > .btn-outline-purple.dropdown-toggle {
  color: #ffffff;
  background-color: #7d00b5;
  border-color: #7d00b5;
}
.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 0, 181, 0.5);
}

.btn-outline-blue-gray {
  color: #4d6f7b;
  border-color: #4d6f7b;
}
.btn-outline-blue-gray:hover {
  color: #ffffff;
  background-color: #4d6f7b;
  border-color: #4d6f7b;
}
.btn-outline-blue-gray:focus, .btn-outline-blue-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 111, 123, 0.5);
}
.btn-outline-blue-gray.disabled, .btn-outline-blue-gray:disabled {
  color: #4d6f7b;
  background-color: transparent;
}
.btn-outline-blue-gray:not(:disabled):not(.disabled):active, .btn-outline-blue-gray:not(:disabled):not(.disabled).active, .show > .btn-outline-blue-gray.dropdown-toggle {
  color: #ffffff;
  background-color: #4d6f7b;
  border-color: #4d6f7b;
}
.btn-outline-blue-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-gray:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-blue-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 111, 123, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #048d22;
  text-decoration: none;
}
.btn-link:hover {
  color: #024310;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .frn-chat .amplify-tabs .btn-group-lg > .amplify-button {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn, .frn-chat .amplify-tabs .btn-group-sm > .amplify-button {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.6rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #048d22;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .frn-chat .amplify-tabs .btn-group > .amplify-button,
.btn-group-vertical > .btn,
.frn-chat .amplify-tabs .btn-group-vertical > .amplify-button {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover, .frn-chat .amplify-tabs .btn-group > .amplify-button:hover,
.btn-group-vertical > .btn:hover,
.frn-chat .amplify-tabs .btn-group-vertical > .amplify-button:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .frn-chat .amplify-tabs .btn-group > .amplify-button:focus, .btn-group > .btn:active, .frn-chat .amplify-tabs .btn-group > .amplify-button:active, .btn-group > .btn.active, .frn-chat .amplify-tabs .btn-group > .active.amplify-button,
.btn-group-vertical > .btn:focus,
.frn-chat .amplify-tabs .btn-group-vertical > .amplify-button:focus,
.btn-group-vertical > .btn:active,
.frn-chat .amplify-tabs .btn-group-vertical > .amplify-button:active,
.btn-group-vertical > .btn.active,
.frn-chat .amplify-tabs .btn-group-vertical > .active.amplify-button {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .frn-chat .amplify-tabs .btn-group > .amplify-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .frn-chat .amplify-tabs .btn-group > .amplify-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.frn-chat .amplify-tabs .btn-group > .btn-group:not(:last-child) > .amplify-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child), .frn-chat .amplify-tabs .btn-group > .amplify-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.frn-chat .amplify-tabs .btn-group > .btn-group:not(:first-child) > .amplify-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .frn-chat .amplify-tabs .btn-group-sm > .amplify-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .frn-chat .amplify-tabs .btn-group-lg > .amplify-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .frn-chat .amplify-tabs .btn-group-vertical > .amplify-button,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .frn-chat .amplify-tabs .btn-group-vertical > .amplify-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .frn-chat .amplify-tabs .btn-group-vertical > .amplify-button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.frn-chat .amplify-tabs .btn-group-vertical > .btn-group:not(:last-child) > .amplify-button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child), .frn-chat .amplify-tabs .btn-group-vertical > .amplify-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.frn-chat .amplify-tabs .btn-group-vertical > .btn-group:not(:first-child) > .amplify-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .frn-chat .amplify-tabs .btn-group-toggle > .amplify-button,
.btn-group-toggle > .btn-group > .btn,
.frn-chat .amplify-tabs .btn-group-toggle > .btn-group > .amplify-button {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio], .frn-chat .amplify-tabs .btn-group-toggle > .amplify-button input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.frn-chat .amplify-tabs .btn-group-toggle > .amplify-button input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.frn-chat .amplify-tabs .btn-group-toggle > .btn-group > .amplify-button input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox],
.frn-chat .amplify-tabs .btn-group-toggle > .btn-group > .amplify-button input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .frn-chat .amplify-tabs .amplify-textfield .input-group > input,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control, .frn-chat .amplify-tabs .amplify-textfield .input-group > input + .form-control, .frn-chat .amplify-tabs .amplify-textfield .input-group > .form-control + input, .frn-chat .amplify-tabs .amplify-textfield .input-group > input + input,
.input-group > .form-control + .custom-select,
.frn-chat .amplify-tabs .amplify-textfield .input-group > input + .custom-select,
.input-group > .form-control + .custom-file,
.frn-chat .amplify-tabs .amplify-textfield .input-group > input + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.frn-chat .amplify-tabs .amplify-textfield .input-group > .form-control-plaintext + input,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.frn-chat .amplify-tabs .amplify-textfield .input-group > .custom-select + input,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.frn-chat .amplify-tabs .amplify-textfield .input-group > .custom-file + input,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus, .frn-chat .amplify-tabs .amplify-textfield .input-group > input:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child), .frn-chat .amplify-tabs .amplify-textfield .input-group > input:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child), .frn-chat .amplify-tabs .amplify-textfield .input-group:not(.has-validation) > input:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3), .frn-chat .amplify-tabs .amplify-textfield .input-group.has-validation > input:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn, .input-group-prepend .frn-chat .amplify-tabs .amplify-button, .frn-chat .amplify-tabs .input-group-prepend .amplify-button,
.input-group-append .btn,
.input-group-append .frn-chat .amplify-tabs .amplify-button,
.frn-chat .amplify-tabs .input-group-append .amplify-button {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus, .input-group-prepend .frn-chat .amplify-tabs .amplify-button:focus, .frn-chat .amplify-tabs .input-group-prepend .amplify-button:focus,
.input-group-append .btn:focus,
.input-group-append .frn-chat .amplify-tabs .amplify-button:focus,
.frn-chat .amplify-tabs .input-group-append .amplify-button:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn, .input-group-prepend .frn-chat .amplify-tabs .amplify-button + .btn, .frn-chat .amplify-tabs .input-group-prepend .amplify-button + .btn, .input-group-prepend .frn-chat .amplify-tabs .btn + .amplify-button, .frn-chat .amplify-tabs .input-group-prepend .btn + .amplify-button, .input-group-prepend .frn-chat .amplify-tabs .amplify-button + .amplify-button, .frn-chat .amplify-tabs .input-group-prepend .amplify-button + .amplify-button,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .frn-chat .amplify-tabs .amplify-button + .input-group-text,
.frn-chat .amplify-tabs .input-group-prepend .amplify-button + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .frn-chat .amplify-tabs .input-group-text + .amplify-button,
.frn-chat .amplify-tabs .input-group-prepend .input-group-text + .amplify-button,
.input-group-append .btn + .btn,
.input-group-append .frn-chat .amplify-tabs .amplify-button + .btn,
.frn-chat .amplify-tabs .input-group-append .amplify-button + .btn,
.input-group-append .frn-chat .amplify-tabs .btn + .amplify-button,
.frn-chat .amplify-tabs .input-group-append .btn + .amplify-button,
.input-group-append .frn-chat .amplify-tabs .amplify-button + .amplify-button,
.frn-chat .amplify-tabs .input-group-append .amplify-button + .amplify-button,
.input-group-append .btn + .input-group-text,
.input-group-append .frn-chat .amplify-tabs .amplify-button + .input-group-text,
.frn-chat .amplify-tabs .input-group-append .amplify-button + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .frn-chat .amplify-tabs .input-group-text + .amplify-button,
.frn-chat .amplify-tabs .input-group-append .input-group-text + .amplify-button {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .frn-chat .amplify-tabs .amplify-textfield .input-group-lg > input:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control, .frn-chat .amplify-tabs .amplify-textfield .input-group-lg > input,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.frn-chat .amplify-tabs .input-group-lg > .input-group-prepend > .amplify-button,
.input-group-lg > .input-group-append > .btn,
.frn-chat .amplify-tabs .input-group-lg > .input-group-append > .amplify-button {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea), .frn-chat .amplify-tabs .amplify-textfield .input-group-sm > input:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control, .frn-chat .amplify-tabs .amplify-textfield .input-group-sm > input,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.frn-chat .amplify-tabs .input-group-sm > .input-group-prepend > .amplify-button,
.input-group-sm > .input-group-append > .btn,
.frn-chat .amplify-tabs .input-group-sm > .input-group-append > .amplify-button {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .frn-chat .amplify-tabs .input-group > .input-group-prepend > .amplify-button,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.frn-chat .amplify-tabs .input-group:not(.has-validation) > .input-group-append:not(:last-child) > .amplify-button,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.frn-chat .amplify-tabs .input-group.has-validation > .input-group-append:nth-last-child(n+3) > .amplify-button,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.frn-chat .amplify-tabs .input-group > .input-group-append:last-child > .amplify-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .frn-chat .amplify-tabs .input-group > .input-group-append > .amplify-button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.frn-chat .amplify-tabs .input-group > .input-group-prepend:not(:first-child) > .amplify-button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.frn-chat .amplify-tabs .input-group > .input-group-prepend:first-child > .amplify-button:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 2.4rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.7rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #048d22;
  background-color: #048d22;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #18f849;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #4afa70;
  border-color: #4afa70;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.7rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.7rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #048d22;
  background-color: #048d22;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 141, 34, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(4, 141, 34, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 141, 34, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.7rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 141, 34, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #18f849;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.4rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 2rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #18f849;
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #048d22;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #4afa70;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #048d22;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #4afa70;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #048d22;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #4afa70;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #048d22;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container, .navbar .div,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-2xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-right: 1rem;
  font-size: 2rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .div,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-2xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container, .navbar-expand-sm > .div,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-2xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .div,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-2xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container, .navbar-expand-md > .div,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-2xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .div,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-2xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container, .navbar-expand-lg > .div,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-2xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .div,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-2xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container, .navbar-expand-xl > .div,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-2xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container, .navbar-expand > .div,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-2xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container, .navbar-expand > .div,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-2xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(34, 34, 34, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(34, 34, 34, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(34, 34, 34, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(34, 34, 34, 0.5);
  border-color: rgba(34, 34, 34, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2834, 34, 34, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(34, 34, 34, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(34, 34, 34, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(34, 34, 34, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(34, 34, 34, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(34, 34, 34, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border-top: 1px solid rgba(34, 34, 34, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #048d22;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #024310;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #048d22;
  border-color: #048d22;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 2rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge, .frn-chat .amplify-tabs .amplify-button .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #048d22;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #035b16;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 141, 34, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #ff9400;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #cc7600;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 148, 0, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #222;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #090909;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 34, 34, 0.5);
}

.badge-purple {
  color: #ffffff;
  background-color: #7d00b5;
}
a.badge-purple:hover, a.badge-purple:focus {
  color: #ffffff;
  background-color: #5a0082;
}
a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(125, 0, 181, 0.5);
}

.badge-blue-gray {
  color: #ffffff;
  background-color: #4d6f7b;
}
a.badge-blue-gray:hover, a.badge-blue-gray:focus {
  color: #ffffff;
  background-color: #39535c;
}
a.badge-blue-gray:focus, a.badge-blue-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 111, 123, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.9rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #125a22;
  background-color: #cde8d3;
  border-color: #b9dfc1;
}
.alert-primary hr {
  border-top-color: #a8d7b2;
}
.alert-primary .alert-link {
  color: #0a3012;
}

.alert-secondary {
  color: #955d10;
  background-color: #ffeacc;
  border-color: #ffe1b8;
}
.alert-secondary hr {
  border-top-color: #ffd69f;
}
.alert-secondary .alert-link {
  color: #67400b;
}

.alert-success {
  color: #256734;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #184121;
}

.alert-info {
  color: #1c6570;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #124047;
}

.alert-warning {
  color: #957514;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #68520e;
}

.alert-danger {
  color: #832c34;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #5d1f25;
}

.alert-light {
  color: #919292;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #777979;
}

.alert-dark {
  color: #222222;
  background-color: lightgray;
  border-color: #c1c1c1;
}
.alert-dark hr {
  border-top-color: #b4b4b4;
}
.alert-dark .alert-link {
  color: #090909;
}

.alert-purple {
  color: #51106e;
  background-color: #e5ccf0;
  border-color: #dbb8ea;
}
.alert-purple hr {
  border-top-color: #d1a4e4;
}
.alert-purple .alert-link {
  color: #300a41;
}

.alert-blue-gray {
  color: #384a50;
  background-color: #dbe2e5;
  border-color: #cdd7da;
}
.alert-blue-gray hr {
  border-top-color: #becbcf;
}
.alert-blue-gray .alert-link {
  color: #232e32;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 1.2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #048d22;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(34, 34, 34, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #048d22;
  border-color: #048d22;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #125a22;
  background-color: #b9dfc1;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #125a22;
  background-color: #a8d7b2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #125a22;
  border-color: #125a22;
}

.list-group-item-secondary {
  color: #955d10;
  background-color: #ffe1b8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #955d10;
  background-color: #ffd69f;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #955d10;
  border-color: #955d10;
}

.list-group-item-success {
  color: #256734;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #256734;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #256734;
  border-color: #256734;
}

.list-group-item-info {
  color: #1c6570;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1c6570;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #1c6570;
  border-color: #1c6570;
}

.list-group-item-warning {
  color: #957514;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #957514;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #957514;
  border-color: #957514;
}

.list-group-item-danger {
  color: #832c34;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #832c34;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #832c34;
  border-color: #832c34;
}

.list-group-item-light {
  color: #919292;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #919292;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #919292;
  border-color: #919292;
}

.list-group-item-dark {
  color: #222222;
  background-color: #c1c1c1;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #222222;
  background-color: #b4b4b4;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

.list-group-item-purple {
  color: #51106e;
  background-color: #dbb8ea;
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #51106e;
  background-color: #d1a4e4;
}
.list-group-item-purple.list-group-item-action.active {
  color: #ffffff;
  background-color: #51106e;
  border-color: #51106e;
}

.list-group-item-blue-gray {
  color: #384a50;
  background-color: #cdd7da;
}
.list-group-item-blue-gray.list-group-item-action:hover, .list-group-item-blue-gray.list-group-item-action:focus {
  color: #384a50;
  background-color: #becbcf;
}
.list-group-item-blue-gray.list-group-item-action.active {
  color: #ffffff;
  background-color: #384a50;
  border-color: #384a50;
}

.close {
  float: right;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  color: #222;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #222;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(34, 34, 34, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #222;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #222;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #222;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #222;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #222;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #222;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 34, 34, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(34, 34, 34, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  color: #222;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #048d22 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #035b16 !important;
}

.bg-secondary {
  background-color: #ff9400 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cc7600 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #222 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #090909 !important;
}

.bg-purple {
  background-color: #7d00b5 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #5a0082 !important;
}

.bg-blue-gray {
  background-color: #4d6f7b !important;
}

a.bg-blue-gray:hover, a.bg-blue-gray:focus,
button.bg-blue-gray:hover,
button.bg-blue-gray:focus {
  background-color: #39535c !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #048d22 !important;
}

.border-secondary {
  border-color: #ff9400 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #222 !important;
}

.border-purple {
  border-color: #7d00b5 !important;
}

.border-blue-gray {
  border-color: #4d6f7b !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(34, 34, 34, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(34, 34, 34, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #048d22 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #024310 !important;
}

.text-secondary {
  color: #ff9400 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #b36800 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #222 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-purple {
  color: #7d00b5 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #480069 !important;
}

.text-blue-gray {
  color: #4d6f7b !important;
}

a.text-blue-gray:hover, a.text-blue-gray:focus {
  color: #30454c !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(34, 34, 34, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container, .div {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #222;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #ffffff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*
$font-size-h5:                $font-size-base;

$font-size-h4:                ceil(($font-size-base * 1.333));
$font-size-lg-md:             ceil(($font-size-base * 1.555));
$font-size-h3:                ceil(($font-size-base * 1.777));
$font-size-lg-xl:             ceil(($font-size-base * 1.999));
$font-size-h2:                ceil(($font-size-base * 2.369));
$font-size-h1:                ceil(($font-size-base * 3.157));
$font-size-4xl:               ceil(($font-size-base * 3.989));
$font-size-5xl:               ceil(($font-size-base * 4.978));
$font-size-6xl:               ceil(($font-size-base * 5.989));
*/
.font-size-medium {
  font-size: 1.4rem;
}

.font-size-regular {
  font-size: 1.6rem;
}

.text-large, .textLarge {
  font-size: 1.8rem;
}

.textCenter {
  text-align: center;
}

.textUppercase {
  text-transform: uppercase;
}

html {
  font-size: 10px;
}

body {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.6rem;
  color: #222;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 800;
  margin-top: 1.5em;
}
h1.light, h2.light, h3.light, h4.light, h5.light, h6.light {
  color: #ffffff;
}
.text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6 {
  color: white;
}
.pagetitle h1, .pagetitle h2, .pagetitle h3, .pagetitle h4, .pagetitle h5, .pagetitle h6 {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 575.98px) {
  .pagetitle h1, .pagetitle h2, .pagetitle h3, .pagetitle h4, .pagetitle h5, .pagetitle h6 {
    margin-bottom: 0.8rem;
  }
}
.card-header h1, .card-header h2, .card-header h3, .card-header h4, .card-header h5, .card-header h6 {
  margin-top: 0;
  margin-bottom: 0;
}
h1.card-title, h2.card-title, h3.card-title, h4.card-title, h5.card-title, h6.card-title {
  margin-top: 0;
  margin-bottom: 0.5em;
}
.post-form h1, .post-form h2, .post-form h3, .post-form h4, .post-form h5, .post-form h6 {
  margin-top: 0;
}

.pagetitle {
  margin-top: 1.6rem;
  margin-bottom: 2rem;
}

.list-group-item {
  font-size: 1.8rem;
}

a.list-group-item {
  color: #222;
  text-decoration: none;
}

a.list-group-item:hover {
  background: #f8f9fa;
}

.text-800 {
  font-weight: 800;
}

.text-medium-gray {
  color: #9b9b9b;
}

.btn.pill, .frn-chat .amplify-tabs .pill.amplify-button, .btn.kep-login-facebook, .frn-chat .amplify-tabs .kep-login-facebook.amplify-button {
  line-height: 2 !important;
  font-size: 1.2rem;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  text-transform: none !important;
  border-radius: 24px/50% !important;
  border: none !important;
  outline: none !important;
  width: 200px;
  height: 45px;
}
.btn.square, .frn-chat .amplify-tabs .square.amplify-button {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  border-radius: 0;
}

[role=button] {
  cursor: pointer;
}

.btn-orange {
  background-color: #ff9400;
  border-color: #ff9400;
  color: #FFF;
}
.btn-orange:hover {
  background-color: #e68500;
  border-color: #e68500;
}

.btn-link-light {
  color: #FFF;
}

.btn, .frn-chat .amplify-tabs .amplify-button {
  white-space: normal;
}
.btn-cta, .frn-chat .amplify-tabs .amplify-button, .btn-exit-orange {
  background: linear-gradient(#fd7e14, #f57102);
  border-color: #cc6900;
  color: #ffffff;
  font-weight: 800;
  line-height: 1;
  padding: 1.0625rem 1rem 1rem;
  text-shadow: none;
  text-transform: uppercase;
  width: 100%;
}
@media (min-width: 576px) {
  .btn-cta, .frn-chat .amplify-tabs .amplify-button, .btn-exit-orange {
    min-width: 350px;
    width: auto;
  }
  .btn-cta.btn-block, .frn-chat .amplify-tabs .btn-block.amplify-button, .btn-block.btn-exit-orange, .btn-cta.w-100, .frn-chat .amplify-tabs .w-100.amplify-button, .w-100.btn-exit-orange {
    min-width: 0;
    width: 100%;
  }
}
.btn-cta:hover, .frn-chat .amplify-tabs .amplify-button:hover, .btn-exit-orange:hover, .btn-cta:focus, .frn-chat .amplify-tabs .amplify-button:focus, .btn-exit-orange:focus {
  background: linear-gradient(#f06e02, #f06e02);
  border-color: #cc6900;
  color: #ffffff;
}
.btn-cta.disabled, .frn-chat .amplify-tabs .disabled.amplify-button, .disabled.btn-exit-orange, .btn-cta.active, .frn-chat .amplify-tabs .active.amplify-button, .active.btn-exit-orange {
  color: #ffffff;
}
.btn-img {
  padding: 0;
}
.btn-outline {
  background: #ffffff;
  border-color: #828282;
  color: #828282;
  font-weight: 800;
  line-height: 1;
  padding: 1.0625rem 1rem 1rem;
  text-shadow: none;
  text-transform: uppercase;
}
.btn-outline:hover, .btn-outline:focus {
  background: rgba(34, 34, 34, 0.1);
  border-color: #828282;
  color: #828282;
}
.btn-upsell-gray {
  background: #9b9b9b;
  border-color: #9b9b9b;
  color: #ffffff;
  line-height: 1;
  padding: 1.0625rem 1rem 1rem;
  text-shadow: none;
  width: 100%;
}
@media (min-width: 576px) {
  .btn-upsell-gray {
    min-width: 350px;
    width: auto;
  }
  .btn-upsell-gray.btn-block {
    width: 100%;
  }
}
.btn-upsell-gray:hover, .btn-upsell-gray:focus {
  background: #828282;
  border-color: #828282;
  color: #ffffff;
}
.btn-exit-orange {
  font-size: 1.25rem;
}
.btn-exit-gray {
  background: linear-gradient(#9b9b9b, #8e8e8e);
  border-color: #8e8e8e;
  color: #ffffff;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  padding: 1.0625rem 1rem 1rem;
  text-shadow: none;
  width: 100%;
  font-size: 1.25rem;
}
.btn-exit-gray:hover, .btn-exit-gray:focus {
  background: linear-gradient(#828282, #757575);
  border-color: #757575;
  color: #ffffff;
}
.btn-upsell-check {
  background: #28a745;
  border: 0;
  color: #ffffff;
  font-weight: 800;
  line-height: 1;
  padding: 1.0625rem 1rem 1rem;
  text-shadow: none;
  text-transform: uppercase;
  width: 100%;
}
.btn-upsell-check:hover, .btn-upsell-check:focus {
  background: #1e7e34;
  color: #ffffff;
}
.btn-size-350 {
  width: 100%;
}
@media (min-width: 576px) {
  .btn-size-350 {
    min-width: 350px;
    width: auto;
  }
}
@media (max-width: 767.98px) {
  .btn-lg, .btn-group-lg > .btn, .frn-chat .amplify-tabs .btn-group-lg > .amplify-button {
    font-size: 1.76rem;
  }
}
.btn-link {
  border-radius: 0;
  font-size: 1.6rem;
  padding: 0;
  vertical-align: baseline;
}
@media (max-width: 767.98px) {
  .btn-link {
    font-size: 1.28rem;
  }
}
.btn-link-checkout {
  color: #28a745;
  text-decoration: underline;
}
.btn-link-checkout:hover, .btn-link-checkout:focus {
  color: #1e7e34;
}
.btn-download {
  padding: 0.75em;
}
.btn-download .btn-icon {
  font-size: 2.8rem;
  margin: 0 0.75em 0 0;
}
@media (min-width: 992px) {
  .btn-download .btn-icon {
    margin: 0 0.5em 0 0;
  }
}
@media (min-width: 1200px) {
  .btn-download .btn-icon {
    margin: 0 1em 0 0.5em;
  }
}
.btn-download .btn-text {
  font-weight: 700;
  text-align: left;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .btn-download .btn-text {
    font-size: 1.28rem;
  }
}
.btn-scroll {
  background-color: rgba(34, 34, 34, 0.3);
  bottom: 5px;
  color: #ffffff;
  line-height: 1;
  padding: 6px 6px 2px 6px;
  position: absolute;
  right: 0;
}
.btn-scroll:hover, .btn-scroll:active, .btn-scroll:focus {
  background-color: rgba(34, 34, 34, 0.5);
  color: #ffffff;
}
.btn-scroll.scrollVisible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s;
}
.btn-scroll.scrollHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s;
}

.form-control.login-input, .frn-chat .amplify-tabs .amplify-textfield input.login-input {
  color: #222;
  font-weight: 100;
  border-color: #cdd1d4;
  line-height: 2;
  font-weight: 100 !important;
  background-color: #fbfbfb;
  border-radius: 6px;
  margin-bottom: 14px;
  outline: none !important;
}
.form-control.login-input::placeholder, .frn-chat .amplify-tabs .amplify-textfield input.login-input::placeholder {
  color: #cdd1d4;
  text-align: center;
}
.form-control.login-input:focus, .frn-chat .amplify-tabs .amplify-textfield input.login-input:focus {
  outline: none;
}

input[type=checkbox] + label {
  height: 24px;
  width: 24px;
  background-size: contain !important;
  padding: 0 0 0 32px;
  margin-right: 6px;
  cursor: pointer;
  color: transparent;
  font-size: 0.1rem;
}

.card {
  background-color: #ffffff;
  border: none;
  color: #222;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
  margin-bottom: 1.6rem;
}
.card .card-horizontal {
  display: flex;
  flex: 1 1 auto;
}
.card-img-bg {
  border-radius: calc(0.25rem - 1px);
}
.card-img-bg, .card-img-bg-top, .card-img-bg-bottom {
  width: 100%;
  padding-bottom: 66.66%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card-img-bg-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bg-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-img-bg-left {
  flex: 0 0 30%;
  width: 30%;
  padding: 10% 0;
  background-position: center;
  background-size: contain;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-img-align-top .card-img-bg, .card-img-align-top .card-img-bg-top, .card-img-align-top .card-img-bg-bottom {
  background-position: top;
}
.card-4-3 .card-img-bg, .card-4-3 .card-img-bg-top, .card-4-3 .card-img-bg-bottom {
  padding-bottom: 75%;
}
.card-1-1 .card-img-bg, .card-1-1 .card-img-bg-top, .card-1-1 .card-img-bg-bottom {
  padding-bottom: 100%;
}
.card-button {
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card-button:hover {
  cursor: pointer;
  transform: scale3d(1.01, 1.01, 1);
  box-shadow: 0 1px 4px 0 rgb(186, 202, 213);
}
.card.bonus-content-card .card-body {
  padding-top: 36px;
  padding-bottom: 8px;
}
.card.bonus-content-card .card-body .card-title {
  color: #222 !important;
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 24px !important;
}
.card.bonus-content-card .card-body .card-subtitle {
  font-size: 1.6rem;
  margin-bottom: 24px !important;
  font-weight: 400;
}
.card.bonus-content-card .card-body .card-text {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 400;
}
.card.bonus-content-card .likesAndBookmark {
  padding-top: 0;
  display: flex;
  padding-bottom: 1.25rem;
  justify-content: flex-end;
}
.card.bonus-content-card .likesAndBookmark > div {
  cursor: pointer;
  padding-left: 16px;
  font-size: 32px;
}
.card.bonus-content-card .likesAndBookmark > div.make-me-pink {
  color: #e91e63;
}
.card.bonus-content-card .likesAndBookmark > div.make-me-black {
  color: #37474f;
}
.card-content {
  font-size: 1.4rem;
}
.card-content .card-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #37474f;
}
.card-content .card-title a {
  color: #37474f;
}
.card-content .card-title a :hover {
  text-decoration: none;
}
.card-content .card-title, .card-content .card-img-bg-top, .card-content .card-img-bg-bottom, .card-content .card-img-top,
.card-content .card-img-bottom, .card-content .card-subtitle {
  cursor: pointer;
}
.card-content .card-footer {
  border-top: none;
  background: unset;
}
.card-dashboard-tile {
  text-align: center;
  cursor: pointer;
  margin: 0;
}
.card-dashboard-tile .card-img-bg {
  padding-bottom: 78.7878787879%;
}
.card-dashboard-tile .card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.card-dashboard-tile .card-title {
  color: #ffffff;
}
.card-socials {
  text-align: right;
}
.card-socials > svg {
  cursor: pointer;
}
.card.product-card {
  display: hidden;
}
.card.product-card .card-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: left;
}
.card.product-card .card-body .product-card-img {
  height: 68px;
  width: 68px;
  min-width: 68px;
  margin-right: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media print {
  .card.product-card .card-body .product-card-img {
    height: 0;
    display: none;
  }
}
.card.user-product .card-body {
  display: flex;
  align-items: center;
  color: #222;
}
.card.user-product .card-body .card-img-top {
  width: 125px;
  height: 125px;
}
.card.user-product .card-body > div {
  margin-left: 1.25rem;
}
.card.user-product .card-body .card-text {
  font-size: 1.6rem;
}
.card.user-recipe {
  width: 116px;
  margin: 0 6px 12px 6px;
}
.card.user-recipe .card-img-top {
  width: 116px;
  height: 116px;
  cursor: pointer;
}
.card.user-recipe .card-text {
  padding: 12px 6px 6px 12px;
  font-size: 12px;
}
.card.user-recipe .card-text > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card.user-recipe .card-text > div span {
  color: grey;
}
.card.user-recipe .card-text > div [class|=star] {
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: url("../images/star/star-filled.png") 0 0px no-repeat;
  background-size: contain;
}
.card.user-recipe .card-text > div [class|=star][class$=-unfilled] {
  width: 10px;
  height: 10px;
  background-size: contain !important;
  background: url("../images/star/star-unfilled.png") 0 0px no-repeat;
}
.card.user-recipe > div {
  position: relative;
}
.card.user-recipe > div .absolute-positioned {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  text-align: center;
  position: absolute;
  font-size: 10px;
  background-color: #e91e63;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  bottom: 6px;
  right: 6px;
  color: white;
  cursor: pointer;
}
.card.user-recipe-big {
  width: 250px;
  margin: 0 6px 12px 6px;
}
.card.user-recipe-big .card-img-top {
  width: 250px;
  height: 160px;
  cursor: pointer;
}
.card.user-recipe-big .card-text {
  padding: 12px 6px 6px 12px;
  font-size: 18px;
}
.card.user-recipe-big .card-text > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card.user-recipe-big .card-text > div span {
  color: grey;
}
.card.user-recipe-big .card-text > div [class|=star] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url("../images/star/star-filled.png") 0 0px no-repeat;
  background-size: contain;
}
.card.user-recipe-big .card-text > div [class|=star][class$=-unfilled] {
  width: 16px;
  height: 16px;
  background-size: contain !important;
  background: url("../images/star/star-unfilled.png") 0 0px no-repeat;
}
.card.user-recipe-big > div {
  position: relative;
}
.card.user-recipe-big > div .absolute-positioned {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  text-align: center;
  position: absolute;
  font-size: 18px;
  background-color: #e91e63;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  bottom: 10px;
  right: 10px;
  color: white;
  cursor: pointer;
}
.card-recipe .card-img-bg-top, .card-recipe .card-title {
  cursor: pointer;
}
.card-recipe .card-footer {
  background: #ffffff;
  border-top: none;
}
.card-recipe-rating {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-recipe-rating > svg {
  width: 20% !important;
  color: #FFC107;
  cursor: pointer;
}
.card.audio-container {
  background: #f5f7fa;
}

ul {
  margin-bottom: 0;
}

.removePadding {
  padding: 0 !important;
}

.grey-background {
  background-color: none;
}
@media (min-width: 768px) {
  .grey-background {
    background-color: #FAFAFA;
  }
}

.pointerGlobal {
  cursor: pointer !important;
}

.heading-5 {
  font-weight: 800;
  font-size: 2rem;
}

.table-middle-align td, .table-middle-align th {
  vertical-align: middle;
}

@media (min-width: 768px) {
  body.oval-bg {
    background-attachment: fixed;
    background-image: url("data:image/svg+xml,%3Csvg width='1594' height='740' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='797' cy='370' rx='797' ry='370' fill='%23EAF4F2'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: calc(-51.530740276vw + 500px);
    background-size: 111%;
  }
}
@media print {
  * {
    color: black;
    background: none;
    border: none;
  }
  body {
    background: #FFF;
  }
}
.countdown-timer {
  margin-top: 1em;
}
@media (min-width: 768px) {
  .countdown-timer {
    margin-top: 0;
  }
}
.countdown-element {
  display: inline-block;
  text-align: center;
}
.countdown-digit {
  background-color: #7d00b5;
  border-radius: 6px;
  color: #ffffff;
  font-size: 2.8rem;
  font-weight: 700;
  margin-right: 6px;
  padding: 10px 6px;
  width: 60px;
}
@media (min-width: 576px) {
  .countdown-digit {
    font-size: 3.2rem;
    width: 80px;
  }
}
.countdown-element:last-child .countdown-digit {
  margin-right: 0;
}
.countdown-white .countdown-digit {
  background-color: #ffffff;
  color: #222;
}

.img-responsive {
  display: block;
  width: 100%;
  height: auto;
}

.background-contain {
  background-size: contain !important;
  background-repeat: no-repeat;
}

.skeleton .form-control, .skeleton .frn-chat .amplify-tabs .amplify-textfield input, .frn-chat .amplify-tabs .amplify-textfield .skeleton input, .skeleton label {
  color: transparent;
  background-color: #CFCFCF;
  border: 0;
}

@media (max-width: 575.98px) {
  .xs-full-width {
    margin-left: -15px;
    margin-right: 15px;
    width: 100vw;
  }
}
.background-white {
  background: #FFF;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ot-sdk-container {
  font-size: 1.75em;
}

.ot-sdk-show-settings {
  border: 0 !important;
  color: #ffffff !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  padding: 0 !important;
}
.ot-sdk-show-settings:hover, .ot-sdk-show-settings:focus {
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse, .text-pending {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .text-pending::before, .dot-pulse::after, .text-pending::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before, .text-pending::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after, .text-pending::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}
@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}
@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}
.text-pending {
  color: transparent;
}

.appMainContainer {
  display: flex;
}

.appContentColumn {
  flex-basis: 100%;
  max-width: 100%;
  padding-top: 63px;
  transition: padding-left 0.4s ease-in-out;
}
@media (min-width: 768px) {
  .appContentColumn.addGreyBackground {
    background-color: #f5f7fa;
  }
}
.appContentColumnHasSidebar {
  padding-left: 300px;
}
@media print {
  .appContentColumnHasSidebar {
    padding-left: 0;
    padding-top: 0;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.addeventatc {
  display: none;
}

.frnBreadCrumbs {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media print {
  .frnBreadCrumbContainer {
    display: none;
  }
}

/* Rectangle: */
.appHeaderContainer {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  transition: padding-left 0.4s ease-in-out;
  transition: margin-left 0.4s ease-in-out;
  color: white;
  padding: 16px 4px;
  height: 63px;
  justify-content: space-between;
  background-image: linear-gradient(to left, #7D00B5, #B400DB);
}
.appContentColumnHasSidebar .appHeaderContainer {
  margin-left: -300px;
  padding-left: 300px;
}
.appHeaderContainer .appNavBarBrand {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  color: white;
}
@media print {
  .appHeaderContainer {
    display: none;
  }
}
.appHeaderContainer .appNavBarBarIcon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 24px;
}
.appHeaderContainer .appNavBarBarIcon path {
  color: white;
}

.headerColumn {
  text-align: center;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar .align-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Rectangle: */
.appHeaderContainer {
  z-index: 999999;
  position: fixed;
  top: 0;
  width: 100%;
  transition: padding-left 0.4s ease-in-out;
  transition: margin-left 0.4s ease-in-out;
  display: flex;
  align-items: center;
  color: white;
  height: 73px;
  padding: 0px;
  justify-content: space-between;
  background: #7d00b5;
}
.modal-open .appHeaderContainer {
  z-index: 999 !important;
}

.appNavBarBrand {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  color: white;
}

.appNavBarBarIcon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 24px;
}
.appNavBarBarIcon path {
  color: white;
}

.headerColumn {
  text-align: center;
}

.colorize-pink {
  filter: brightness(0.5) sepia(1) hue-rotate(-70deg) saturate(5);
}

.medicalDisclaimer {
  font-size: 90%;
  margin-top: 180px;
  padding-top: 45px;
}

.hideThis {
  display: none !important;
}

.showMoreNav {
  margin-left: -80vw;
  width: 80vw;
  z-index: 3000;
  height: 100vw;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  margin-top: 0px;
  padding-top: 10px;
  transform: translateX(-80vw);
  transition: all 0.4s ease-in-out;
  color: black;
  background: #e8e8e8;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.closeMark {
  margin-top: -10px;
  float: right;
  margin-right: 8px;
  font-size: 32px;
}

.makeVisible {
  margin-left: 0px;
  transform: translateX(0);
}

.showMoreNavItem {
  width: 100%;
  border-bottom: 1px solid #7d7e80;
  display: flex;
  align-items: center;
}
.showMoreNavItemText {
  font-size: 16px;
}
@media (min-width: 768px) {
  .showMoreNavItemText {
    font-size: 22px;
    height: 22px;
  }
}
.showMoreNavItemIcon {
  width: 55px;
}

.productSidebarChildren {
  box-sizing: border-box;
  margin-left: 0px;
  width: 100%;
}
@media (min-width: 992px) {
  .productSidebarChildren {
    padding-left: 220px;
  }
}
@media print and (min-width: 992px) {
  .productSidebarChildren {
    padding-left: 0px !important;
  }
}

.activeNav {
  color: #048d22;
}

.productSideBarContainer {
  box-sizing: border-box;
  min-height: 80px;
  background: #e8e8e8;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  color: black;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .productSideBarContainer {
    display: block;
  }
}
.productSideBarContainerNavItem {
  height: 60px;
  margin: 10px;
  display: inline-block;
  text-align: center;
  padding: 5px;
  line-height: 14px;
  font-size: 14px;
}
.productSideBarContainerNavItem:hover {
  cursor: pointer;
  color: #048d22;
}
@media (min-width: 992px) {
  .productSideBarContainerNavItem {
    border-bottom: 1px solid #7d7e80;
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .productSideBarContainer {
    box-shadow: -4px 60px 8px 3px rgba(186, 202, 213, 0.63);
    min-height: 100vh;
    height: 100%;
    width: 80px;
    z-index: 100;
    position: fixed;
    margin-right: -80px;
    transform: translateX(-80px);
    text-align: center;
    padding-top: 80px;
  }
  .productSideBarContainerNavItem {
    margin-top: 8px;
    height: auto;
  }
  .productSideBarContainer.makeSideBarVisible {
    transform: translateX(0);
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .productSideBarContainer.makeSideBarVisible {
    width: 220px;
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .productSideBarContainer .sideBarFooter {
    padding: 20px;
  }
}
@media (min-width: 992px) and (min-width: 992px) {
  .productSideBarContainer .sideBarFooter {
    position: absolute;
    bottom: 0;
  }
}
@media (min-width: 992px) {
  .productSideBarContainer .sideBarNavExt {
    background: #440062;
    color: white;
    padding: 16px 4px;
    height: 63px;
  }
  .productSideBarContainer .sideBarLogo {
    display: flex;
    justify-content: center;
    padding-top: 36px;
    text-align: center;
  }
  .productSideBarContainer .sideBarLogo > svg {
    height: 75px;
  }
  .productSideBarContainer .sideBarMain .sideBarOverview {
    margin-bottom: 16px;
    padding-top: 30px;
    padding-left: 24px;
  }
  .productSideBarContainer .sideBarMain .sideBarOverview span {
    letter-spacing: 1.33px;
  }
  .productSideBarContainer .sideBarSignOut {
    padding-left: 24px;
    padding-top: 8px;
    height: 63px;
  }
  .productSideBarContainer .sideBarSignOut span {
    cursor: pointer;
  }
}
@media print and (min-width: 992px) {
  .productSideBarContainer {
    display: none;
  }
}
@media (min-width: 992px) {
  .productSideBarContainer .sideBarArrowIcon {
    font-size: 24px;
    cursor: pointer;
    margin-left: 24px;
  }
  .productSideBarContainer .sideBarArrowIcon path {
    color: white;
  }
}

.pptStyles {
  padding-top: inherit;
}
@media (min-width: 992px) {
  .pptStyles {
    padding-top: 160px;
  }
}

.customImgIcon {
  width: 20px;
}

.ActivecustomImgIcon {
  -webkit-filter: drop-shadow(83px 0 0 #048D22);
  filter: drop-shadow(83px 0 0 #048D22);
  position: absolute;
  left: -83px;
}

.baseIcon {
  position: relative;
  margin-right: 22px;
}

.hideThis {
  display: none;
}

.sideBarContainer {
  box-shadow: -4px 60px 8px 3px rgba(186, 202, 213, 0.63);
  min-height: 100vh;
  height: 100%;
  width: 300px;
  background: #ffffff;
  z-index: 1030;
  position: fixed;
  margin-right: -300px;
  transform: translateX(-300px);
  transition: all 0.4s ease-in-out;
}
.sideBarContainer.makeSideBarVisible {
  margin-right: -100vw;
  width: 100vw;
  transform: translateX(0);
}
@media (min-width: 768px) {
  .sideBarContainer.makeSideBarVisible {
    width: 300px;
    margin-right: 0;
  }
}
.sideBarContainer .sideBarFooter {
  padding: 20px;
}
@media (min-width: 768px) {
  .sideBarContainer .sideBarFooter {
    position: absolute;
    bottom: 0;
  }
}
.sideBarContainer .sideBarNavExt {
  background: #440062;
  color: white;
  padding: 16px 4px;
  height: 63px;
}
.sideBarContainer .sideBarLogo {
  display: flex;
  justify-content: center;
  padding-top: 36px;
  text-align: center;
}
.sideBarContainer .sideBarLogo > svg {
  height: 75px;
}
.sideBarContainer .sideBarMain .sideBarOverview {
  margin-bottom: 16px;
  padding-top: 30px;
  padding-left: 24px;
}
.sideBarContainer .sideBarMain .sideBarOverview span {
  letter-spacing: 1.33px;
}
.sideBarContainer .sideBarSignOut {
  padding-left: 24px;
  padding-top: 8px;
  height: 63px;
}
.sideBarContainer .sideBarSignOut span {
  cursor: pointer;
}
@media print {
  .sideBarContainer {
    display: none;
  }
}
.sideBarContainer .sideBarArrowIcon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 24px;
}
.sideBarContainer .sideBarArrowIcon path {
  color: white;
}

.appV2MainContainer {
  margin: 0px;
  padding-top: 73px;
}

@media print {
  .appV2MainContainer {
    padding-top: 0px;
  }
}
.tagStyles {
  background-color: #17a2b8;
  min-width: 64px;
  max-width: 72px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.landingPageBG {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  background-image: url("../images/landing-page-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #58056D;
}

.genericInputContainer {
  margin-bottom: 24px !important;
}
.genericInputContainer label {
  display: block;
  margin-bottom: 4.5px !important;
}
.genericInputContainer input {
  width: 100%;
}

.inputRangeTicks {
  display: flex;
  justify-content: space-between;
  padding: 0 0.7rem;
}
.inputRangeTicks datalist {
  display: none;
}
.inputRangeTicks i {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 1px;
  background: #D3D3D3;
  height: 10px;
  line-height: 40px;
  margin: 0 0 20px 0;
}

.profileInputStyles {
  margin-bottom: 9px !important;
  background-color: #dfe3e6;
}

.modalContainer {
  margin: 0 auto;
  max-width: 372px !important;
}
.modalContainer > div {
  padding: 36px;
}
.modalContainer b {
  color: #cdd1d4;
}
.modalContainer .modalCloseButton {
  cursor: pointer;
}
.modalContainer .modalFooter {
  padding-bottom: 0 !important;
}
.modalContainer .close {
  position: absolute;
  top: 5px;
  right: 10px;
}

.headerUserInfoContainer {
  padding-right: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.headerUserInfoDropdown button {
  cursor: pointer;
  max-width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.headerUserInfoImg {
  text-align: center;
  width: 32px;
  height: 32px;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 8px;
  display: inline-block;
}

#beacon-container {
  display: none;
}
@media (min-width: 992px) {
  #beacon-container {
    display: block;
  }
}

.headerUserInfoV2Container {
  padding: 5px 10px 5px 0px;
  cursor: pointer;
  float: right;
  height: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.headerUserInfoV2Dropdown {
  padding: 0px;
  float: right;
}
.headerUserInfoV2Dropdown button {
  cursor: pointer;
  max-width: 100vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.headerUserInfoV2Wrapper {
  width: 100%;
}

.headerUserInfoV2ProductContainer {
  background: none;
  cursor: pointer;
  line-height: 1;
  margin-right: 14px;
}
@media (min-width: 576px) {
  .headerUserInfoV2ProductContainer {
    background: orange;
    padding: 16px 10px 16px 10px;
    border-radius: 10px;
    height: 22px;
    margin-right: 18px;
  }
}

.headerUserInfoV2ProductContainerText {
  display: none;
}
@media (min-width: 576px) {
  .headerUserInfoV2ProductContainerText {
    display: inline;
    font-size: 16px;
  }
}

.headerUserInfoImg {
  text-align: center;
  width: 32px;
  height: 32px;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: #666;
}

.headerUserInfoText {
  display: none;
}
@media (min-width: 576px) {
  .headerUserInfoText {
    display: inline-block;
    height: 20px;
  }
}

.seperatorHeader {
  height: 24px;
  margin-top: 0;
  margin-left: 10px;
  margin-right: 10px;
  width: 1px;
  background-color: white;
}
@media (min-width: 992px) {
  .seperatorHeader {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.listText {
  overflow: hidden;
  height: 20px;
}

.listSpace {
  width: 0.3em;
  height: 100%;
  display: inline-block;
}

.list-group-item {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.list-group-item a:hover {
  background-color: transparent;
}

.list-group-item a {
  background-color: transparent;
}

.truncate {
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides any text that overflows the container */
  text-overflow: ellipsis; /* Adds an ellipsis (...) to indicate truncated text */
  max-width: 50px; /* Set the width of the container */
  width: 50px;
}

.list-group-white {
  color: var(--white);
}
.list-group-white .list-group-item, .list-group-white svg {
  color: var(--white);
}
.list-group-white .list-group-item:hover {
  color: #e2e6ea;
  background: transparent;
}
.list-group-white .list-group-item:hover svg {
  color: #e2e6ea;
}

.checkboxSuccess input {
  background-color: #5cb85c;
  border-color: #5cb85c;
  cursor: pointer;
}

.pointerGlobal {
  cursor: pointer;
}

.closeOutFilter {
  color: white;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -11px;
}

.closeOutModal {
  top: 0;
  right: 4px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
  z-index: 1000;
}

.modalGlobal {
  word-wrap: "break-word";
}

.tagsGlobal {
  line-height: 1.1;
}

.lineThrough {
  text-decoration: line-through;
}

.appSideBarItemContainer {
  cursor: pointer;
  padding: 8px 0 8px 24px;
}
.appSideBarItemContainer .appSideBarItemTitle {
  position: relative;
}
.appSideBarItemContainer .appSideBarItemTitle > div {
  display: inline-block;
}
.appSideBarItemContainer .appSideBarItemTitle:first-child {
  margin-right: 8px;
}
.appSideBarItemContainer .appSideBarItemIcon {
  margin-right: 20px;
  height: 25px;
  width: 25px;
  background: #28A745;
  border-radius: 2px;
  vertical-align: middle;
  text-align: center;
  line-height: 25px;
}
.appSideBarItemContainer .appSideBarItemIcon > svg {
  height: 100%;
}
.appSideBarItemContainer .appSideBarCollapseToggle {
  position: absolute;
  right: 20px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #D9D6EB;
  fill: #D9D6EB;
}
.appSideBarItemContainer .appSideBarItemChildren ul {
  list-style: none;
  color: rgb(74, 74, 74);
  line-height: 20px;
  padding: 25px 0 17px 0;
}
.appSideBarItemContainer .appSideBarItemChildren li {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.appSideBarItemContainer .appSideBarItemChildren li .sideBarChildBullet {
  margin-right: 30px;
  padding: 5px 0;
}
.appSideBarItemContainer .appSideBarItemChildren li span {
  display: inline-block;
}
@media (min-width: 768px) {
  .appSideBarItemContainer .appSideBarItemChildren li {
    max-width: 225px;
  }
}

add-to-calendar-button#custom-add-to-calendar-button::part(atcb-button),
#atcb-btn-css-part-example-modal-host::part(atcb-button) {
  background-color: #ff9400;
  color: #fff;
  margin-left: 0;
}

add-to-calendar-button#custom-add-to-calendar-button::part(atcb-button-wrapper) {
  margin-left: 0;
  padding-left: 0;
}

.appSideBarItemContainer {
  cursor: pointer;
  padding: 8px 0 8px 24px;
}
.appSideBarItemContainer .appSideBarItemTitle {
  position: relative;
}
.appSideBarItemContainer .appSideBarItemTitle > div {
  display: inline-block;
}
.appSideBarItemContainer .appSideBarItemTitle:first-child {
  margin-right: 8px;
}
.appSideBarItemContainer .appSideBarItemIcon {
  margin-right: 20px;
  height: 25px;
  width: 25px;
  background: #28A745;
  border-radius: 2px;
  vertical-align: middle;
  text-align: center;
  line-height: 25px;
}
.appSideBarItemContainer .appSideBarItemIcon > svg {
  height: 100%;
}
.appSideBarItemContainer .appSideBarCollapseToggle {
  position: absolute;
  right: 20px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #D9D6EB;
  fill: #D9D6EB;
}
.appSideBarItemContainer .appSideBarItemChildren ul {
  list-style: none;
  color: rgb(74, 74, 74);
  line-height: 20px;
  padding: 25px 0 17px 0;
}
.appSideBarItemContainer .appSideBarItemChildren li {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.appSideBarItemContainer .appSideBarItemChildren li .sideBarChildBullet {
  margin-right: 30px;
  padding: 5px 0;
}
.appSideBarItemContainer .appSideBarItemChildren li span {
  display: inline-block;
}
@media (min-width: 768px) {
  .appSideBarItemContainer .appSideBarItemChildren li {
    max-width: 225px;
  }
}

.orderConfirmationTop {
  margin-top: 16px;
}

.orderConfirmationMargin {
  margin-left: 16px;
  margin-right: 16px;
}

.orderProcessed {
  margin-right: 0;
}

.myProductsButton {
  width: 100%;
  color: grey;
}

.myProductsButton:hover {
  cursor: pointer;
}

.grey {
  background-color: lightgrey;
}

.bold {
  font-weight: 700;
}

nameWidth {
  width: 75%;
}

priceWidth {
  margin-left: 8px;
}

.option {
  height: 75px;
  border-right: 1px solid grey !important;
  border-left: 1px solid grey !important;
  padding-left: 12px;
  padding-right: 0;
}

.onlyOption {
  border-radius: 8px 8px 8px 8px !important;
  border: 1px solid grey !important;
}

.topOption {
  border-radius: 8px 8px 0 0 !important;
  border-top: 1px solid grey !important;
  border-bottom: 0 !important;
}

.middleOption {
  border-top: 1px solid grey !important;
  border-radius: 0 0 0 0 !important;
  border-bottom: 0 !important;
}

.lastOption {
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
  border-radius: 0 0 8px 8px !important;
}

.checkedGradient {
  background-image: linear-gradient(-180deg, #EEEEEE 0%, #D8D8D8 100%);
}

.uncheckedGradient {
  background-image: linear-gradient(0deg, #FFFFFF 0%, #E4E4E4 100%);
}

.black {
  color: black;
  font-weight: 400;
}

.green {
  color: green;
  font-weight: 400;
}

.sup {
  font-size: 16px !important;
  padding-right: 2px;
  transform: translateY(-0.55em);
}

.dynamicSize {
  font-size: 1em;
}

@media only screen and (min-width: 425px) {
  .dynamicSize {
    font-size: 1.5em;
    margin-left: 8px;
  }
}
.myProductsContainer span {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}
.myProductsContainer .myProductsTitle {
  margin: 36px 0 36px 18px;
}
.myProductsContainer .myProductsTitle span {
  font-size: 18px;
}
@media (min-width: 768px) {
  .myProductsContainer .myProductsTitle span {
    font-size: 24px;
  }
}
.myProductsContainer .myProductsListContainer {
  display: flex;
  flex-wrap: wrap;
}
.myProductsContainer .myProductsListContainer > div {
  margin: 0px 0px 24px 0px;
  flex-basis: 100%;
  cursor: pointer;
}
@media (min-width: 768px) {
  .myProductsContainer .myProductsListContainer > div {
    margin: 8px 8px 24px 8px;
    flex-basis: 46%;
    margin-bottom: 28px;
  }
}
@media (min-width: 992px) {
  .myProductsContainer .myProductsListContainer > div {
    flex-basis: 33%;
    margin-bottom: 36px;
  }
}

.breadCrumbs {
  font-size: 0.8rem;
}
@media (min-width: 576px) {
  .breadCrumbs {
    font-size: 1.6rem;
  }
}

.circle-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-position: top;
  background-size: cover;
}

.comingSoon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.8;
  color: white;
}
.comingSoon H5, .comingSoon H3 {
  color: white;
  text-align: center;
}

.btn-secondary {
  background: #ff9400;
  max-width: 100%;
  white-space: normal;
}

.greyedOut {
  opacity: 0.5;
}

.greyBorder {
  border: thin solid grey;
}

.EPWrapper a {
  color: #7d7e80;
}
.EP-broadcastTitle {
  position: relative;
  background: #8f00cf;
  color: white;
  height: 100px;
}
.EP-broadcastTitle:after {
  content: "";
  background-image: url("images/card-bg-veggies.svg");
  background-size: cover;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
@media (min-width: 768px) {
  .EP-broadcastTitle {
    height: 120px;
  }
}
.EP-textCenter {
  font-size: 4rem;
  line-height: 1.1;
}

.EP-day:hover {
  cursor: pointer;
}

.EP-day-seakerName:hover {
  text-decoration: underline;
}

.removePadding {
  padding: 0 !important;
}

.seperator {
  height: 30px;
  width: 1px;
  background-color: white;
}

.shadowGlobal {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
}

.shadow1 {
  margin: 40px;
  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30);
  -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
  zoom: 1;
}

.zIndex100 {
  z-index: 100 !important;
}

.dashboardHeader {
  font-size: 1.2rem;
}
@media (min-width: 992px) {
  .dashboardHeader {
    font-size: 2.2rem;
  }
}

.dashboardHeader h1 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.moduleProgressWrapper {
  padding-top: 0px;
  margin-top: 0px;
  background: #1e2629;
  top: 70px;
  height: 60px;
}
.moduleProgress {
  background: #1e2629;
}

.premieresAtOverlay {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 100%;
  height: 60px;
}
@media (min-width: 992px) {
  .premieresAtOverlay {
    height: 71px !important;
  }
}

.premieresAtOverlay .icon,
.lessonLocked .icon {
  position: absolute;
  left: 32px;
  top: 10px;
  padding: 0px;
  margin: 0px;
  font-size: 30px;
}
@media (min-width: 992px) {
  .premieresAtOverlay .icon,
  .lessonLocked .icon {
    left: 42px !important;
    top: 16px !important;
  }
}

.premieresAtOverlay {
  color: white;
  height: 1.3em;
  font-size: 1.3em;
}

.titleOverlay {
  position: absolute;
  color: white;
  top: 24px;
  left: 140px;
  font-size: 0.8em !important;
  font-weight: 700;
  padding: 0px;
  opacity: 0.5;
}

.timeOverlay {
  position: absolute;
  top: 14px;
  right: 15px;
  font-size: 0.8em !important;
}
@media (min-width: 992px) {
  .timeOverlay {
    top: 18px;
  }
}

.timeOverlay span {
  font-size: 1.3em;
  font-weight: 700;
  line-height: 0.7em;
}

.premieresAtBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 100%;
  background: black;
  height: 60px;
  opacity: 0.8 !important;
}
@media (min-width: 992px) {
  .premieresAtBackdrop {
    height: 71px !important;
  }
}

.premieresAtOverlay h6 {
  color: white;
}

.cardPremiere {
  height: 60px;
}
@media (min-width: 992px) {
  .cardPremiere {
    height: 71px;
  }
}

.moduleHeader div:first-child {
  font-size: 2.6rem;
  font-weight: bold;
  color: black;
}

.lessonsHeader span:first-child {
  font-size: 1.9rem;
  font-weight: 700;
}

.lessonLocked {
  background-color: #222;
}

.lessonLocked .lessonVideoThumbnail {
  opacity: 0.75;
}

.iconFontSize {
  font-size: 20px;
}

.fullScreenModal {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  padding: 6px;
}
@media (min-width: 992px) {
  .fullScreenModal {
    width: 80% !important;
    height: 80% !important;
    max-width: 80% !important;
    margin-left: 10%;
    margin-top: 5%;
    padding: 6px;
  }
}

.moduleTitle {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
}

.col {
  color: #222;
}

.lessonHeading {
  font-size: 22px;
  font-weight: 700;
  color: #222;
}

.lessonTitle {
  font-size: 1.6rem;
  padding-left: 5px;
}
@media (min-width: 992px) {
  .lessonTitle {
    padding-left: 15px;
  }
}

.lessonLocked .lessonTitle {
  color: white;
  opacity: 0.8;
}

.lessonImage {
  position: relative;
}
@media (min-width: 992px) {
  .lessonImage {
    padding-right: 15px;
  }
}

.lessonImage img {
  width: 80px;
}
@media (min-width: 992px) {
  .lessonImage img {
    width: 100px;
  }
}

.lessonImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px;
  padding: 0px;
  height: 100px;
  width: 100px;
  border: thin solid red;
}

.modulePageHeader {
  height: 60px;
}
.moduleHeader h2,
h4 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.articleContainer .articleCopy img {
  max-width: 100%;
}
.articleContainer ul {
  padding-bottom: 4rem;
}
.articleContainer .articleHeaderImage {
  margin-bottom: 36px;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
.articleContainer .articleTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.articleContainer .articleAuthorContainer {
  display: flex;
  margin: 36px 0;
}
.articleContainer .articleAuthorContainer > div {
  margin-right: 16px;
}
.articleContainer .articleAuthorContainer .articleAuthorImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.audioContainer {
  margin-bottom: 48px !important;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
.audioContainer audio {
  display: block;
}
.audioContainer:last-of-type {
  margin-bottom: 54.5454545455px !important;
}
@media (min-width: 576px) {
  .audioContainer {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 575.98px) {
  .audioContainer {
    margin-left: 0;
    margin-right: 0;
  }
  .audioContainer .card-body {
    padding: 15px;
  }
}

.apmDownloadsListWide a {
  color: #222;
}
.apmDownloadsListWide span {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.apmPictureV2 img {
  width: 100%;
}

.authorCardContainer {
  box-shadow: none !important;
  background-color: transparent;
  width: 350px;
}
.authorCardContainer .authorTagsBox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  margin-top: 4px;
}
.authorCardContainer .authorTagsBox > div {
  margin-right: 5px;
  margin-top: 4px;
}
.authorCardContainer .authorCardCardBody {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 576px) {
  .authorCardContainer .authorCardCardBody {
    flex-direction: column;
  }
}
.authorCardContainer .authorCardCardBody .authorInfoBox {
  display: flex;
}
.authorCardContainer .authorCardCardBody .authorInfoBox .authorImg {
  height: 46px;
  width: 46px;
  margin-right: 24px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.authorCardContainer .authorCardCardBody .authorInfoBox .authorTextLines span {
  font-size: 16px;
}
.authorCardContainer .authorCardCardBody .authorInfoBox .authorTextLines .authorInfo span {
  color: #007bff;
  cursor: pointer;
}

.audioContainer {
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
.audioContainer audio {
  display: block;
}
.audioContainer:last-of-type {
  margin-bottom: 54.5454545455px !important;
}
@media (min-width: 576px) {
  .audioContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

.destroyPadding {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bonusPageTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusPageTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.bonusesList {
  margin-bottom: 60px !important;
}

.destroyPadding {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bonusPageTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusPageTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.bonusesList {
  margin-bottom: 60px !important;
}

.broadcastsListContainer {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 32px;
  margin-bottom: 18px !important;
}

.broadist {
  margin-bottom: 9px !important;
}

.broadcastListTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .broadcastListTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.subTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
}

.broadcastsPlusContainer {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 32px;
  margin-bottom: 18px !important;
}

.broadcastsPlusList {
  margin-bottom: 9px !important;
}

.broadcastsPlusListTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .broadcastsPlusListTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.subTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
}

.carouselContainer {
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .carouselContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

.carouselTitle {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
}

.carouselStyle {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carouselStyle .carouselControls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  width: 100%;
  background: transparent;
}
.carouselStyle .carouselControls .arrow {
  z-index: 1000;
  padding-top: 28px;
  color: #ffffff;
  width: 48px;
  height: 75px;
  background: hsla(0, 100%, 0%, 0.5);
  text-align: center;
  cursor: pointer;
}

.linkStyle .carouselImg {
  cursor: pointer;
  background: lightblue;
  background-size: cover;
  background-position: center;
  text-align: center;
  height: 75px;
  padding-top: 28px;
  color: white;
}
.linkStyle:hover {
  cursor: pointer;
  text-decoration: none;
}

.frn-chat img {
  max-width: 100%;
  width: auto;
  height: auto;
}
.frn-chat textarea {
  field-sizing: content;
}
.frn-chat .btn-chat-login {
  margin: 0.5em auto;
  min-width: 0;
  width: 80%;
}
.frn-chat .amplify-tabs {
  padding: 1.5rem;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .frn-chat .amplify-tabs {
    max-width: 600px;
    width: 100%;
  }
}
.frn-chat .amplify-tabs .amplify-tabs__list {
  display: none;
}
.frn-chat .amplify-tabs .amplify-textfield {
  margin-bottom: 0.5rem;
}
.frn-chat .amplify-tabs .amplify-textfield .amplify-label {
  font-weight: 800;
  margin-bottom: 0.25rem;
}
.frn-chat .amplify-tabs .amplify-textfield .amplify-field__error-message {
  color: #dc3545;
}
.frn-chat .amplify-tabs .amplify-button {
  margin-top: 1rem;
  min-width: none;
  width: 100%;
}
.frn-chat .amplify-log-in-hidden {
  display: none;
}
.frn-chat .btn:active, .frn-chat .amplify-tabs .amplify-button:active {
  border-color: rgba(255, 255, 255, 0);
}
.frn-chat .btn-reply {
  color: #7d00b5;
  padding: 5px 0;
}
.frn-chat .btn-reply:focus, .frn-chat .btn-reply:hover {
  color: #5a0082;
  text-decoration: underline;
}
.frn-chat .btn-refresh {
  background-color: #4267b2;
}
.frn-chat .btn-refresh:focus, .frn-chat .btn-refresh:hover {
  color: #365899;
}
.frn-chat .btn-nav {
  color: #6f6f6f;
  padding: 5px;
}
.frn-chat .btn-nav:focus, .frn-chat .btn-nav:hover {
  color: #222;
  text-decoration: underline;
}
.frn-chat .btn-load-more {
  background-color: gainsboro;
}
.frn-chat .btn-load-more:focus, .frn-chat .btn-load-more:hover {
  background-color: #cfcfcf;
}
.frn-chat .btn-post {
  background: #4267b2;
  border-color: #4267b2;
  color: #ffffff;
  font-weight: 600;
  line-height: 1;
}
.frn-chat .btn-post:focus, .frn-chat .btn-post:hover {
  background: #365899;
  border-color: #365899;
}
.frn-chat .btn-mod-link {
  text-decoration: underline;
  padding: 0;
  vertical-align: baseline;
}
.frn-chat .btn-mod-link:focus, .frn-chat .btn-mod-link:hover {
  color: #484848;
}
.frn-chat .btn-mod-comment {
  background-color: #d9edf7;
  color: #31708f;
}
.frn-chat .btn-mod-comment:focus, .frn-chat .btn-mod-comment:hover {
  background-color: #afd9ee;
  color: #245269;
}
.frn-chat .btn-mod-user {
  background-color: #f2dede;
  color: #a94442;
}
.frn-chat .btn-mod-user:focus, .frn-chat .btn-mod-user:hover {
  background-color: #e4b9b9;
  color: #843534;
}
.frn-chat .chat-inner {
  position: relative;
}
.frn-chat .chat-messages {
  max-height: 55vh;
}
@media (min-width: 768px) {
  .frn-chat .chat-messages {
    max-height: 70vh;
  }
}
.frn-chat .message-thread {
  border-left: 1px solid #e8e8e8;
}
.frn-chat .message-wrap {
  background-color: #f5f5f5;
}
.frn-chat .message-user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.frn-chat .alert-warning {
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  color: #8a6d3b;
}
.frn-chat .alert-info {
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  color: #31708f;
}
.frn-chat .alert-danger {
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  color: #a94442;
}
.frn-chat .alert-success {
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  color: #3c763d;
}

.checklistContainer, .gettingStartedVideoContainer {
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}
@media (max-width: 575.98px) {
  .checklistContainer, .gettingStartedVideoContainer {
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 30px);
    padding-right: 30px;
    padding-left: 30px;
  }
}
.checklistContainerReadOnly .checklistItem, .gettingStartedVideoContainerReadOnly .checklistItem {
  cursor: unset !important;
}

.checklistContainer, .gettingStartedVideoContainer {
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}
@media (max-width: 575.98px) {
  .checklistContainer, .gettingStartedVideoContainer {
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 30px);
    padding-right: 30px;
    padding-left: 30px;
  }
}
.checklistContainer h5, .gettingStartedVideoContainer h5 {
  padding-bottom: 10px;
  border-bottom: 1px solid #bacad5;
  margin-bottom: 40px;
}
.checklistContainerReadOnly .checklistItem, .gettingStartedVideoContainerReadOnly .checklistItem {
  cursor: unset !important;
}

.collectionContainer {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 32px;
  margin-bottom: 18px !important;
  max-width: 100vw;
}

.list {
  margin-bottom: 9px !important;
}

.listTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .listTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.subTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
}

.destroyPadding {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bonusPageTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusPageTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.bonusesList {
  margin-bottom: 60px !important;
}
.bonusesList .card-title {
  font-size: 2rem;
}

.courseContainer {
  border: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0;
  margin-bottom: 18px !important;
}

.courseDescription {
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .courseDescription {
    margin-left: 0;
    margin-right: 0;
  }
}

.courseHackySpacing {
  width: 100%;
  margin-bottom: 54.5454545455px !important;
}

.courseTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .courseTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.fullWidthListContainer {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 32px;
  margin-bottom: 18px !important;
}

.list {
  margin-bottom: 9px !important;
}

.listTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .listTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.subTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
}

.guidelinesContainer {
  margin: 24px 0;
}

.interactWideContainer .interact {
  cursor: pointer;
  margin-bottom: 8px;
}
.interactWideContainer .interact.interactLike {
  display: flex;
}
.interactWideContainer .interact.interactLike .interactLiked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: hotpink;
}
.interactWideContainer .interact.interactLike .interactNotLiked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: url("../images/heart/heart-large.png") 0 0px no-repeat;
}
.interactWideContainer .interact.interactBookmark {
  display: flex;
}
.interactWideContainer .interact.interactBookmark .interactBookmarked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: hotpink;
}
.interactWideContainer .interact.interactBookmark .interactNotBookmarked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: green;
}
.interactWideContainer .interact span {
  font-size: 16px;
}
.interactWideContainer .interact.interactPrint {
  display: flex;
  padding-right: 12px;
}
.interactWideContainer .interact.interactPrint > div {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: green;
}

.interactNarrowContainer {
  display: flex;
}
.interactNarrowContainer .interact {
  cursor: pointer;
  margin-bottom: 8px;
}
.interactNarrowContainer .interact.interactLike {
  display: flex;
}
.interactNarrowContainer .interact.interactLike .interactLiked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: hotpink;
}
.interactNarrowContainer .interact.interactLike .interactNotLiked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: url("../images/heart/heart-large.png") 0 0px no-repeat;
}
.interactNarrowContainer .interact.interactBookmark {
  display: flex;
}
.interactNarrowContainer .interact.interactBookmark .interactBookmarked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: hotpink;
}
.interactNarrowContainer .interact.interactBookmark .interactNotBookmarked {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: green;
}
.interactNarrowContainer .interact span {
  font-size: 16px;
}
.interactNarrowContainer .interact.interactPrint {
  display: flex;
  padding-right: 12px;
}
.interactNarrowContainer .interact.interactPrint > div {
  width: 26px;
  height: 24px;
  margin-right: 16px;
  background: green;
}

.landingContainer {
  border: none !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0;
  margin-bottom: 18px !important;
}

.markDownContainer {
  margin-top: 32px;
  margin-bottom: 18px !important;
}
.markDownContainer h1 {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
}
.markDownContainer h3 {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
}
.markDownContainer h5 {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
}

.onboardTitleModalHeader {
  padding: 16px;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 8px;
}
.onboardTitleModalHeader .onboardTitle {
  text-align: center;
  margin-bottom: 8px;
}
.onboardTitleModalHeader .onboardTitle span {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  font-size: 22px;
}
.onboardTitleModalHeader .onboardUserClose {
  text-decoration: underline;
}
.onboardTitleModalHeader .onboardUserClose span {
  color: #048d22;
}

.modalCloseButton {
  text-align: center;
  width: 100%;
}
.modalCloseButton button {
  margin-right: 16px;
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.product-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 0;
  height: 0;
  display: flex;
  flex-direction: row;
}
.product-nav-open {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
}
.product-nav-open .product-nav-menu {
  left: 0;
  width: 320px;
  opacity: 1;
}
.product-nav-menu {
  position: fixed;
  top: 0;
  width: 0;
  left: -320px;
  opacity: 0;
  height: 100%;
  background-color: var(--dark);
  color: var(--white);
  transition: all 0.3s;
  z-index: 1000;
  font-size: 2.25rem;
  padding-top: 25px;
}
.product-nav-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.product-nav-menu a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: var(--white);
}
.product-nav-menu a:hover {
  font-weight: bold;
}
.product-nav-fill {
  display: flex;
  flex: 1;
}

.recipeContainer .recipeImage {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -99;
  margin-bottom: -63px;
}
@media screen {
  .recipeContainer .recipeImage {
    content: none !important;
  }
}
.recipeContainer .recipeMetaTitle {
  text-transform: uppercase;
  font-weight: 700;
  color: #868e96;
  font-size: 1.4rem;
}
.recipeContainer .recipeMetaValue {
  color: #222;
  font-size: 1.4rem;
}
.recipeContainer .recipeServingsArrow {
  color: #222;
  cursor: pointer;
  margin-left: 5px;
}
.recipeContainer .recipeMenuBar {
  background-color: #ffffff;
  padding: 5rem 0;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
  margin-bottom: 3.5rem;
}
@media (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar {
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
  }
}
.recipeContainer .recipeMenuBar .recipeTitleRow {
  width: 100%;
  margin-bottom: 16px;
}
@media (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeTitleRow {
    text-align: center;
  }
}
.recipeContainer .recipeMenuBar .recipeTitleRow .recipePrintCol {
  justify-content: flex-end;
  text-align: right;
  font-size: 2rem;
  line-height: 1.2;
}
.recipeContainer .recipeMenuBar .recipeTitleRow .recipePrintCol > svg {
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeTitleRow .recipePrintCol {
    justify-content: center;
    text-align: center;
  }
}
.recipeContainer .recipeMenuBar .recipeMenuBars {
  margin: 0;
  padding: 0;
}
@media (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars {
    padding: 1rem 0;
  }
}
.recipeContainer .recipeMenuBar .recipeMenuBars .row {
  margin: 0;
  padding: 0;
}
.recipeContainer .recipeMenuBar .recipeMenuBars .row > div {
  margin-bottom: 1.5rem;
}
.recipeContainer .recipeMenuBar .recipeMenuBars .row > div > div {
  display: inline-block;
}
.recipeContainer .recipeMenuBar .recipeMenuBars .recipeMenuIcon {
  font-size: 3.5rem;
  margin-right: 1rem;
  color: #868e96;
}
@media (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars .recipeMenuIcon {
    font-size: 2.6rem;
  }
}
@media (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars .recipeServingsArrow {
    display: none;
  }
}
.recipeContainer .recipeMenuBar .recipeMenuStarsAndBars {
  flex-direction: row-reverse;
}
@media (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuStarsAndBars > div {
    padding-left: 0;
    padding-right: 0;
  }
}
.recipeContainer .recipeMenuBar .recipeMenuAllergensTitle {
  margin-bottom: 1.5rem;
}
.recipeContainer .recipeMenuBar .recipeMenuAllergens > div {
  display: flex;
}
.recipeContainer .recipeMenuBar .recipeMenuAllergens > div > div {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 13px;
  width: 27px;
  height: 27px;
}
.recipeContainer .recipeMenuBar .recipeMenuAllergens .recipeMenuAllergensRecipePics {
  display: none;
  display: flex;
  justify-content: right;
  color: purple;
}
.recipeContainer .recipeMenuBar .recipeMenuAllergens .recipeMenuAllergensRecipePics > div {
  flex-basis: 33%;
  text-align: right;
}
.recipeContainer ul,
.recipeContainer ol {
  margin-bottom: 0;
  padding-left: 16px;
}
.recipeContainer .recipeIngredientPrintable,
.recipeContainer .recipePrintTitle {
  display: none;
}
@media print {
  .recipeContainer {
    font-size: 1.8rem;
  }
  .recipeContainer .container, .recipeContainer .div {
    width: 100%;
    margin: 0;
    padding: 20px;
  }
  .recipeContainer .recipeImage {
    height: auto;
    width: 300px;
    float: right;
  }
  .recipeContainer .recipeIngredientPrintable {
    display: block;
    list-style-position: inside;
  }
  .recipeContainer .recipePrintTitle {
    display: block;
    font-size: 3.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .recipeContainer .recipeIngredient,
  .recipeContainer .recipePrintCol,
  .recipeContainer .recipeTitleRow,
  .recipeContainer svg[data-icon=arrow-alt-down],
  .recipeContainer svg[data-icon=arrow-alt-up] {
    display: none !important;
  }
  .recipeContainer .recipeImage {
    margin-left: 15px;
    margin-bottom: 2rem;
  }
  .recipeContainer .recipeMenuBar {
    padding: 0;
  }
  .recipeContainer .recipeMenuBar .recipeMenuAllergens {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    print-color-adjust: exact !important; /*Firefox*/
  }
  .recipeContainer .recipeMenuStarsAndBars {
    text-align: left;
    display: block;
  }
  .recipeContainer .recipeMenuStarsAndBars .recipeMenuBars {
    margin-left: -15px;
  }
  .recipeContainer .recipeMenuStarsAndBars .recipeStarsContainer {
    justify-content: unset;
  }
  .recipeContainer .recipeMenuStarsAndBars .container, .recipeContainer .recipeMenuStarsAndBars .div,
  .recipeContainer .recipeMenuStarsAndBars .row,
  .recipeContainer .recipeMenuStarsAndBars div[class^=col-] {
    flex: unset;
    width: auto;
  }
  .recipeContainer .recipeDirectionsContainer {
    padding: 0;
  }
  .recipeContainer .recipeDirectionsContainer > div {
    padding: 1rem 0;
  }
  .recipeContainer .recipeIngredientsDirections h4 {
    margin: 1rem 0;
  }
  .recipeContainer .recipeIngredientsDirections .col-12 {
    display: block;
    width: 100%;
    flex: unset;
    max-width: 100%;
    margin-bottom: 2.4rem !important;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeImage {
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -99;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .recipeContainer .recipeImage {
    content: none !important;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeMetaTitle {
    text-transform: uppercase;
    font-weight: 700;
    color: #868e96;
    font-size: 1.4rem;
  }
  .recipeContainer .recipeMetaValue {
    color: #222;
    font-size: 1.4rem;
  }
  .recipeContainer .recipeServingsArrow {
    color: #222;
    cursor: pointer;
    margin-left: 5px;
  }
  .recipeContainer .recipeMenuBar {
    background-color: #ffffff;
    padding: 5rem 0;
    box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
    margin-bottom: 3.5rem;
  }
}
@media (min-width: 992px) and (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar {
    margin-left: 15px;
    margin-right: 15px;
    width: auto;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeMenuBar .recipeTitleRow {
    width: 100%;
    margin-bottom: 16px;
  }
}
@media (min-width: 992px) and (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeTitleRow {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeMenuBar .recipeTitleRow .recipePrintCol {
    justify-content: flex-end;
    text-align: right;
    font-size: 2rem;
    line-height: 1.2;
  }
  .recipeContainer .recipeMenuBar .recipeTitleRow .recipePrintCol > svg {
    cursor: pointer;
  }
}
@media (min-width: 992px) and (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeTitleRow .recipePrintCol {
    justify-content: center;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars {
    margin: 0;
    padding: 0;
  }
}
@media (min-width: 992px) and (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars {
    padding: 1rem 0;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars .row {
    margin: 0;
    padding: 0;
  }
  .recipeContainer .recipeMenuBar .recipeMenuBars .row > div {
    margin-bottom: 1.5rem;
  }
  .recipeContainer .recipeMenuBar .recipeMenuBars .row > div > div {
    display: inline-block;
  }
  .recipeContainer .recipeMenuBar .recipeMenuBars .recipeMenuIcon {
    font-size: 3.5rem;
    margin-right: 1rem;
    color: #868e96;
  }
}
@media (min-width: 992px) and (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars .recipeMenuIcon {
    font-size: 2.6rem;
  }
}
@media (min-width: 992px) and (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuBars .recipeServingsArrow {
    display: none;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeMenuBar .recipeMenuStarsAndBars {
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) and (max-width: 575.98px) {
  .recipeContainer .recipeMenuBar .recipeMenuStarsAndBars > div {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .recipeContainer .recipeMenuBar .recipeMenuAllergensTitle {
    margin-bottom: 1.5rem;
  }
  .recipeContainer .recipeMenuBar .recipeMenuAllergens > div {
    display: flex;
  }
  .recipeContainer .recipeMenuBar .recipeMenuAllergens > div > div {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-right: 22px;
    width: 55px;
    height: 55px;
  }
  .recipeContainer .recipeMenuBar .recipeMenuAllergens .recipeMenuAllergensRecipePics {
    display: block;
    display: flex;
    justify-content: right;
    color: purple;
  }
  .recipeContainer .recipeMenuBar .recipeMenuAllergens .recipeMenuAllergensRecipePics > div {
    flex-basis: 33%;
    text-align: right;
  }
  .recipeContainer ul,
  .recipeContainer ol {
    margin-bottom: 0;
    padding-left: 16px;
  }
  .recipeContainer .recipeIngredientPrintable,
  .recipeContainer .recipePrintTitle {
    display: none;
  }
}
@media print and (min-width: 992px) {
  .recipeContainer {
    font-size: 1.8rem;
  }
  .recipeContainer .container, .recipeContainer .div {
    width: 100%;
    margin: 0;
    padding: 20px;
  }
  .recipeContainer .recipeImage {
    height: auto;
    width: 300px;
    float: right;
  }
  .recipeContainer .recipeIngredientPrintable {
    display: block;
    list-style-position: inside;
  }
  .recipeContainer .recipePrintTitle {
    display: block;
    font-size: 3.2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .recipeContainer .recipeIngredient,
  .recipeContainer .recipePrintCol,
  .recipeContainer .recipeTitleRow,
  .recipeContainer svg[data-icon=arrow-alt-down],
  .recipeContainer svg[data-icon=arrow-alt-up] {
    display: none !important;
  }
  .recipeContainer .recipeImage {
    margin-left: 15px;
    margin-bottom: 2rem;
  }
  .recipeContainer .recipeMenuBar {
    padding: 0;
  }
  .recipeContainer .recipeMenuBar .recipeMenuAllergens {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    print-color-adjust: exact !important; /*Firefox*/
  }
  .recipeContainer .recipeMenuStarsAndBars {
    text-align: left;
    display: block;
  }
  .recipeContainer .recipeMenuStarsAndBars .recipeMenuBars {
    margin-left: -15px;
  }
  .recipeContainer .recipeMenuStarsAndBars .recipeStarsContainer {
    justify-content: unset;
  }
  .recipeContainer .recipeMenuStarsAndBars .container, .recipeContainer .recipeMenuStarsAndBars .div,
  .recipeContainer .recipeMenuStarsAndBars .row,
  .recipeContainer .recipeMenuStarsAndBars div[class^=col-] {
    flex: unset;
    width: auto;
  }
  .recipeContainer .recipeDirectionsContainer {
    padding: 0;
  }
  .recipeContainer .recipeDirectionsContainer > div {
    padding: 1rem 0;
  }
  .recipeContainer .recipeIngredientsDirections h4 {
    margin: 1rem 0;
  }
  .recipeContainer .recipeIngredientsDirections .col-12 {
    display: block;
    width: 100%;
    flex: unset;
    max-width: 100%;
    margin-bottom: 2.4rem !important;
  }
}

.recipeIngredientsDirections h4 {
  margin-bottom: 2rem;
}

.recipeIngredient {
  margin-bottom: 0.5rem;
  padding: 1.8rem 0;
}
.recipeIngredientAmountLabel {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  color: #868e96;
}
.recipeIngredientImg {
  width: 4.5rem;
  height: 4.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.recipeIngredientNotes {
  color: #868e96;
  font-size: 0.8em;
}

/* COOK mode */
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #048d22;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.toggle-container span {
  margin-left: 10px;
}

.toggle-container label {
  margin-bottom: 0;
}

.FRN-recipe-card-body {
  color: white;
  min-height: 52px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;
  background-color: rgba(98, 0, 184, 0.8);
  cursor: pointer;
}

.FRN-recipe-card-body-text {
  line-height: 1.1;
  font-size: 1.9rem;
}

.recipesGridContainer .col, .recipesGridContainer [class*=col-] {
  margin-bottom: 3rem;
}
.recipesGridContainer .recipesGridBig {
  display: none;
}
@media (min-width: 992px) {
  .recipesGridContainer .recipesGridBig {
    display: flex;
  }
}
.recipesGridContainer .recipesGridSmall {
  display: flex;
}
@media (min-width: 992px) {
  .recipesGridContainer .recipesGridSmall {
    display: none;
  }
}

.recipesListContainer .recipesListTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.recipesListContainer .recipesListTitle span {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  font-size: 20px;
}
.recipesListContainer .recipesListTitle a {
  text-decoration: none;
}

.recipesListRecipes > .col, .recipesListRecipes > [class*=col-] {
  margin-bottom: 30px;
}

.resourcesListContainer {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.resourcesListTitle {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  margin-bottom: 18px !important;
  font-size: 12px;
  font-weight: small;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .resourcesListTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.resourcesListGridContainer > div [class=customBorder] {
  border-bottom: 1px solid #ffffff;
}
.resourcesListGridContainer > div:nth-child(odd) {
  border-right: 1px solid #ffffff;
}
.resourcesListGridContainer > div:nth-last-child(2) [class=customBorder] {
  border-bottom: none !important;
}
.resourcesListGridContainer > div:last-child [class=customBorder] {
  border-bottom: none !important;
}

.scheduleContainer {
  margin-top: 32px;
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .scheduleContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

.scheduleSessionContainer {
  margin-top: 32px;
}

.scheduleTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
}

.scheduleSpacing {
  margin-bottom: 18px !important;
}

.scheduleSubtitleStyles {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
  text-transform: none;
}

.scheduleContentTitle {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  text-transform: none;
}

[class*=addTrailingMargin] {
  margin-bottom: 18px !important;
}

.resourcesListTwoResources .col, .resourcesListTwoResources [class*=col-] {
  margin-bottom: 3rem;
}

.scrollableMarkdown::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
.scrollableMarkdown::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.sessionTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
  margin-top: 24.0601503759px;
  text-transform: none;
  margin-bottom: 9px;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .sessionTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.sessionsListContainer {
  padding: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.sessionsListContainer .sessionsList {
  margin-bottom: 54.5454545455px !important;
}
.sessionsListContainer .sessionsList div > a {
  display: block;
  width: 100%;
  color: #444;
  border-bottom: 1px solid #444;
  text-decoration: none;
}
.sessionsListContainer .sessionsList div > a:last-of-type {
  margin-bottom: 0;
  border-bottom: none;
}
.sessionsListContainer .sessionsList div > a > h5 {
  margin-left: 26px;
  margin-right: 26px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 2.85;
  font-weight: 700;
}
@media (min-width: 576px) {
  .sessionsListContainer .sessionsList div > a > h5 {
    margin-left: 0;
    margin-right: 0;
  }
}
.sessionsListContainer .sessionsList div > a > h5 > div {
  display: inline-block;
  width: 24px;
}
@media (min-width: 576px) {
  .sessionsListContainer .sessionsList div > a > h5 {
    font-size: 24px;
  }
  .sessionsListContainer .sessionsList div > a > h5 > div {
    width: 48px;
  }
}
.sessionsListContainer .sessionsList .sessionsListTitle {
  text-align: center;
  margin-bottom: 36px !important;
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .sessionsListContainer .sessionsList .sessionsListTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.sessionResourcesListContainer {
  margin-bottom: 54.5454545455px !important;
}

.title {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 18px !important;
}

.subTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
  margin-bottom: 36px !important;
  text-transform: none;
}

.list {
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .list {
    margin-left: 0;
    margin-right: 0;
  }
}

.surveyContainer {
  margin-bottom: 18px !important;
}

.surveyTitle {
  margin-bottom: 18px !important;
  padding-bottom: 12px;
  border-bottom: 2px solid #f5f7fa;
}
.surveyTitle span {
  font-size: 2rem;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}

.surveyHeadline {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  margin-bottom: 9px !important;
  width: 100%;
  text-decoration: none;
}

.surveyDescription {
  margin-bottom: 18px !important;
}

.surveyButtonCol {
  text-align: center;
}

.surveyButton {
  margin-bottom: 9px !important;
  margin-top: 32px;
}

.SurveyPagedContainer .progress {
  height: 2.4rem;
  margin-bottom: 1.5rem;
}
.SurveyPagedContainer .progress-bar {
  background-color: #007bff !important;
  background-image: unset;
  font-size: 1rem;
}

.SurveyPagedButtonRow {
  justify-content: center;
}
.SurveyPagedButtonCol:not(:only-child) {
  max-width: 20rem;
}

.toolsContainer {
  width: 100% !important;
  margin-bottom: 18px !important;
}

.list {
  margin-bottom: 36px !important;
}

.listTitle {
  margin-top: 32px;
  margin-bottom: 18px !important;
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
}

.subTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
  margin-bottom: 9px !important;
}

.videoWrapper {
  margin-bottom: 18px !important;
  height: 0;
  margin-bottom: 15px;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}
.videoWrapper iframe,
.videoWrapper video,
.videoWrapper object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loadingVideo {
  position: absolute;
  top: 0;
  left: 15px;
  width: 100%;
  height: 100%;
  background: #7d7e80;
  border-radius: 5px;
  border: thin solid #444;
}

.activelyPlayingVideo {
  background: #f3f4f5;
}

.playListContainer {
  padding-left: 0px;
  margin-bottom: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 0.25rem;
  max-height: 600px;
  overflow: scroll;
  position: relative;
}

.playListVideo {
  padding: 5px 0;
  line-height: 1.25;
  cursor: pointer;
}
.playListVideo-index {
  text-align: center;
  font-size: 1.4rem;
  color: #4d6f7b;
  width: 7%;
}
.playListVideo-title {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  padding: 0.75em;
  width: 65%;
}
.playListVideo-thumbnail {
  width: 32%;
}
.playListVideo-thumbnail img {
  max-width: 100%;
  height: auto;
}
.playListVideo-subtitle {
  font-size: 0.98rem;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 20px;
  color: #4d6f7b;
}

.videoResourceContainer .card-header {
  background: #ffffff;
  border-bottom: none;
}
.videoResourceContainer .videoResourceBioImg {
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 125px;
}
@media (min-width: 768px) {
  .videoResourceContainer .videoResourceBioImg {
    height: 150px;
  }
}
.videoResourceContainer .action-hour-title {
  margin: 15px 0;
}

.videoResourceDownload {
  cursor: pointer;
  font-size: 1.8rem;
}
.videoResourceDownload svg {
  font-size: 1.8rem;
}
.videoResourceDownload a {
  color: #444;
  text-decoration: none;
}

.removeShadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .weekContainer .weekChecklistAndResources {
    padding-left: 0;
    padding-right: 0;
  }
  .weekContainer .weekChecklistAndResources .row {
    padding-left: 0;
    padding-right: 0;
  }
}
.weekContainer .wlcVideoContainer, .weekContainer .weekRecipesList, .weekContainer .weekChecklistAndResources {
  margin-bottom: 5rem;
}
.weekContainer .weekChecklistAndResources {
  padding-left: 0;
  padding-right: 0;
}
.weekContainer .weekRenderOnboardNav {
  position: absolute;
  z-index: 999;
}
.weekContainer .weekRenderOnboardNav span {
  font-size: 16px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  cursor: pointer;
}
.weekContainer .weekRenderOnboardNav .weekRenderOnboardNavCol {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.weekContainer .weekRenderOnboardNav .weekRenderOnboardNavCol span {
  color: #048d22;
  text-decoration: underline;
}
.weekContainer .weekRenderOnboardCompleteButton {
  text-align: center;
}
.weekContainer .weekRenderOnboardCompleteButton button {
  margin-right: 16px;
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.weekRenderOnboardSuccessModal {
  padding: 16px;
}
.weekRenderOnboardSuccessModal span {
  font-size: 16px;
}
.weekRenderOnboardSuccessModal .weekRenderOnboardSuccessModalButtons {
  display: flex;
  justify-content: space-around;
}
.weekRenderOnboardSuccessModal .weekRenderOnboardSuccessModalButtons button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.weekRenderOnboardNavContainer {
  margin-top: 16px;
  z-index: 999;
}
.weekRenderOnboardNavContainer span {
  font-size: 16px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: #048d22;
  text-decoration: underline;
}

.weekRenderOnboardAlert {
  margin-top: 48px;
}

.welcomeContainer {
  margin-bottom: 18px !important;
}

.list {
  margin-bottom: 36px !important;
}

.listTitle {
  margin-top: 32px;
  margin-bottom: 18px !important;
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
}

.subTitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
  margin-bottom: 9px !important;
}

@media (max-width: 575.98px) {
  .zoomWebinarContainer,
  .zoomWebinarContainer > .row,
  .zoomWebinarContainer > .row > .col {
    padding-left: 0;
    padding-right: 0;
  }
  .zoomWebinarContainer > .row,
  .zoomWebinarContainer > .row > .row,
  .zoomWebinarContainer > .row > .col > .row {
    padding-left: 0;
    padding-right: 0;
  }
  .zoomWebinarContainer > .row > .col,
  .zoomWebinarContainer > .row > .row > .col,
  .zoomWebinarContainer > .row > .col > .row > .col {
    padding-left: 0;
    padding-right: 0;
  }
}
.zoomWebinarContainer {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.zoomWebinarContainer .card-header {
  background: #ffffff;
  border-bottom: none;
  padding: 45px;
  padding-bottom: 0;
}
.zoomWebinarContainer .zoomWebinarBioImg {
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 125px;
}
@media (min-width: 768px) {
  .zoomWebinarContainer .zoomWebinarBioImg {
    height: 150px;
  }
}

.zoomWebinarDownload {
  cursor: pointer;
  margin-top: 36px;
  text-align: center;
}
.zoomWebinarDownload svg {
  font-size: 36px;
}
.zoomWebinarDownload a {
  color: #444;
  text-decoration: none;
}

.broadcastTitle {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 18px !important;
}

.broadcastSubtitle {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
  text-transform: none;
}

.ordersOrderBackground {
  background-color: #f5f7fa;
  height: 500vh;
}

.orderViewTitle {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  margin: 36px 0;
}
.orderViewTitle span {
  font-size: 24px;
}
@media print {
  .orderViewTitle > div {
    display: none;
  }
}
.orderViewTitle .orderViewTitleBack span {
  font-size: 12px;
  cursor: pointer;
  color: #048d22;
}

@media print {
  .orderViewPrint {
    display: none;
  }
}

.ordersOrderContainer {
  line-height: 1em;
  margin-bottom: 16px;
  padding: 16px 16px 16px 16px;
}
@media print {
  .ordersOrderContainer {
    border-bottom: 1px solid black;
  }
  .ordersOrderContainer:last-of-type {
    border-bottom: none;
  }
}
.ordersOrderContainer .orderViewSummaryRow {
  display: flex;
  justify-content: space-between;
}
.ordersOrderContainer .orderViewSummaryRow .orderViewOrderPlaced {
  text-align: left;
  flex-basis: 50% !important;
  order: -10;
}
@media (min-width: 576px) {
  .ordersOrderContainer .orderViewSummaryRow .orderViewOrderPlaced {
    flex-basis: unset;
    order: unset;
  }
}
.ordersOrderContainer .orderViewSummaryRow .orderViewOrderTotal {
  margin-top: 16px;
  text-align: right;
  order: 30;
  order: 1;
}
@media (min-width: 576px) {
  .ordersOrderContainer .orderViewSummaryRow .orderViewOrderTotal {
    text-align: left;
    margin-top: 0;
    order: 0;
  }
}
.ordersOrderContainer .orderViewSummaryRow .orderViewOrderNum {
  text-align: right;
  flex-basis: 50% !important;
  order: -1;
}
@media (min-width: 576px) {
  .ordersOrderContainer .orderViewSummaryRow .orderViewOrderNum {
    flex-basis: unset;
    order: unset;
  }
}
.ordersOrderContainer .orderViewSummaryRow .orderViewOrderName {
  margin-top: 16px;
}
.ordersOrderContainer .orderViewBottomBack {
  margin-top: 24px;
}
.ordersOrderContainer .orderViewBottomBack span {
  font-size: 12px;
  cursor: pointer;
  color: #048d22;
}
@media print {
  .ordersOrderContainer .orderViewNoPrint {
    display: none;
  }
}

.ordersOrderRow {
  cursor: pointer;
  padding: 0 16px;
  margin-bottom: 24px;
}
.ordersOrderRow .makeMeGreen {
  color: #048d22;
}

.ordersHomeContainer {
  background: #f5f7fa;
  padding-bottom: 120px;
}
.ordersHomeContainer .ordersHomeTitle {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  margin: 36px 0;
}
.ordersHomeContainer .ordersHomeTitle span {
  font-size: 24px;
}
@media print {
  .ordersHomeContainer .ordersHomePrint {
    display: none;
  }
}
@media print {
  .ordersHomeContainer {
    background-color: none;
  }
  .ordersHomeContainer body {
    color: black;
  }
}
@media print {
  .ordersHomeContainer > div {
    width: 66%;
  }
}

.text400 {
  font-weight: 400 !important;
}
.text600 {
  font-weight: 600 !important;
}
.text700 {
  font-weight: 700 !important;
}
.textUppercase {
  text-transform: uppercase;
}
.textItalic {
  font-style: italic;
}
.textUnderline {
  text-decoration: underline;
}
.textCapitalize {
  text-transform: capitalize;
}
.textStrike {
  text-decoration: line-through;
}
.text2Xs {
  font-size: 0.8rem !important;
  line-height: 1.25em !important;
}
.textXs {
  font-size: 1rem !important;
  line-height: 1.25em !important;
}
.textSm {
  font-size: 1.2rem !important;
  line-height: 1.25em !important;
}
.textMd {
  font-size: 1.4rem !important;
  line-height: 1.25em !important;
}
.textBase {
  font-size: 1.6rem !important;
  line-height: 1.25em !important;
}
.textLg {
  font-size: 2rem !important;
  line-height: 1.25em !important;
}
@media (min-width: 768px) {
  .textLg {
    font-size: 2.4rem !important;
  }
}
.textLgMd {
  font-size: 1.6rem !important;
  line-height: 1.25em !important;
}
.textLgXl {
  font-size: 1.9rem !important;
  line-height: 1.25em !important;
}
.textXl {
  font-size: 3rem !important;
  line-height: 1.25em !important;
}
@media (min-width: 768px) {
  .textXl {
    font-size: 2.8rem !important;
  }
}
.text2Xl {
  font-size: 3.2rem !important;
  line-height: 1.25em !important;
}
@media (max-width: 767px) {
  .text2Xl {
    font-size: 3rem !important;
  }
}
.text3Xl {
  font-size: 4rem !important;
  line-height: 1.25em !important;
}
@media (max-width: 767px) {
  .text3Xl {
    font-size: 3rem !important;
  }
}
.text4Xl {
  font-size: 2.6rem !important;
  line-height: 1.25em !important;
}
.text5Xl {
  font-size: 2.8rem !important;
  line-height: 1.25em !important;
}
.text6Xl {
  font-size: 3rem !important;
  line-height: 1.25em !important;
}

.greyText {
  color: #444 !important;
}

.whiteText {
  color: #ffffff !important;
}

.greenText {
  color: #048d22 !important;
}

.orangeText {
  color: #ff9400 !important;
}

.centerBlock {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.centerText {
  text-align: center;
}

.bgLightGreen {
  background-color: #f4fbf3 !important;
}

.bgLightGrey {
  background-color: #dce0e2 !important;
}

.bgGrey {
  background-color: #eceff1 !important;
}

.bgMedGrey {
  background-color: #7d7e80 !important;
}

.bgPurple {
  background-color: #6C028A !important;
}

.bgDarkGrey {
  background-color: #1e2629 !important;
}

.bgWhite {
  background-color: #ffffff !important;
}

.bgInk {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://cdn.foodrevolution.org/checkout/assets/images/bg-ink-gray.png");
  padding: 10px 0 !important;
}

.imgResponsive {
  display: block;
  max-width: 75%;
  height: auto;
}

.sectionHeader {
  padding: 2em !important;
  text-align: center;
  margin-bottom: 24px !important;
}

.banner, .bannerGreen {
  color: #ffffff;
  position: relative;
  text-align: center;
  padding: 3.2rem 0 !important;
  margin-bottom: 50px !important;
}
.banner::after, .bannerGreen::after {
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  clear: both;
  content: "";
  height: 0;
  position: absolute;
  top: 100%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 0;
  z-index: 999;
}

.banner {
  background-color: #546e7a;
}
.banner::after {
  border-top: 30px solid #546e7a;
}

.bannerGreen {
  background-color: #048d22;
}
.bannerGreen::after {
  border-top: 30px solid #048d22;
}

.bannerHeader {
  margin-bottom: 0 !important;
}

.listStyles .check {
  width: 65px;
  height: 65px;
  margin: 0 auto;
}
.listStyles .checkContainer {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .listStyles .checkContainer {
    margin-top: 1.56em;
  }
}
.listStyles .textStyles {
  text-align: center;
}
@media (min-width: 768px) {
  .listStyles .textStyles {
    text-align: left;
  }
}

.congrats {
  color: #ffffff;
  font-weight: 700;
}
.congratsT {
  line-height: 2.8rem !important;
  font-size: 2em !important;
}
@media (min-width: 768px) {
  .congratsT {
    font-size: 3em !important;
    line-height: 4.8rem !important;
  }
}

.opportunity {
  color: #ffffff;
  font-size: 1.25em;
  margin-top: 0;
}
@media (min-width: 768px) {
  .opportunity {
    font-size: 1.5em;
  }
}

.oneTime {
  color: #ffffff;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  font-size: 3em !important;
  margin: 1.6rem 0 !important;
  line-height: 1.25em !important;
}

.fixedBackground {
  background-image: url("https://cdn.foodrevolution.org/checkout/assets/images/bg-header-welcome.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px 0 20px 0;
}

.limitHeight {
  max-height: 750px;
}

.beforeButtonT {
  font-size: 1.2em !important;
  margin-top: 1em !important;
  text-align: center;
}
@media (min-width: 768px) {
  .beforeButtonT {
    font-size: 1.75em !important;
  }
}

.afterButtonT {
  margin: 1em 0 2em 0 !important;
  text-align: center;
}

.courseContainer {
  background: #FFF;
  border: 4px solid #EEE;
  padding: 22px 12px;
}
@media (min-width: 992px) {
  .courseContainer:first-child {
    margin-right: 10px;
  }
  .courseContainer:last-child {
    margin-left: 10px;
  }
}

.videoContainer {
  max-width: 900px;
  margin: 0 auto;
}
.videoContainerInner {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.bonusStyles {
  min-height: 400px;
}

.bonusTitle {
  font-size: 24px;
}

.bonusImage {
  max-height: 400px;
}

.sectionLifeEasier {
  background: url("https://cdn.foodrevolution.org/checkout/assets/images/bg-healthy-foods-wood-tray.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.communityContainer {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 30%, rgb(255, 255, 255) 42.5%, rgb(255, 255, 255) 100%), url("https://cdn.foodrevolution.org/checkout/upsells/uhc18/community-plants.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
}
@media (max-width: 991px) {
  .communityContainer {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url("https://cdn.foodrevolution.org/checkout/upsells/uhc18/community-plants.jpg");
    background-size: cover;
    background-position: center;
  }
}
.communityContainerInner {
  width: 65%;
  padding-left: 35%;
}
@media (max-width: 991px) {
  .communityContainerInner {
    width: unset;
    padding-left: unset;
  }
}

@media (max-width: 991px) {
  .communityText {
    color: #FFF !important;
    text-align: center;
  }
}

.miniBanner {
  background-color: #4d6f7b;
  margin-top: 0px !important;
  margin-bottom: 20px !important;
  font-size: 1.4em;
  text-align: center;
  padding: 3.2rem 0 !important;
}
@media (min-width: 768px) {
  .miniBanner {
    font-size: 2em;
  }
}
.miniBannerWelcomeT {
  font-weight: 700 !important;
  line-height: 1.25em !important;
}

.lead2T {
  font-size: 1.25em !important;
  color: #444 !important;
  text-align: center;
  margin: 1em 0 !important;
}
@media (min-width: 768px) {
  .lead2T {
    font-size: 1.75em !important;
  }
}

.leadMainT {
  color: #444 !important;
  font-size: 1.75em !important;
  line-height: 1.25 !important;
  text-align: center;
  margin-top: 0 !important;
  text-transform: uppercase;
  font-weight: 700 !important;
}
@media (min-width: 768px) {
  .leadMainT {
    font-size: 3em !important;
  }
}

.title {
  font-size: 3.2rem;
  font-weight: 700;
  background: url("https://cdn.foodrevolution.org/checkout/assets/images/bg-ink-gray.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px 0;
}

.titleT {
  text-align: center;
}

.speakersBackground {
  width: 75%;
}

.speakers {
  background-color: #ffffff;
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
  z-index: 999;
}

.speakerColumn {
  margin-top: 24px;
  max-width: 1280px;
}

.columnSubTitle {
  font-size: 3rem;
}

.columnSubTitleT {
  text-align: center;
}

.speaker {
  width: 375px;
  z-index: 999;
}

.speakerTime {
  background: #6e328a none repeat scroll 0 0;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  left: -10px;
  line-height: 1em;
  padding: 1em 0;
  position: absolute;
  text-align: center;
  top: -10px;
  vertical-align: top;
  width: 50px;
}

.imageContainer {
  position: relative;
  margin-bottom: 16px;
  max-height: 360px;
  width: 95%;
}

.image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.speakerName {
  bottom: 0;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 700;
  left: 0;
  margin-bottom: 10px;
  padding: 0 10px;
  position: absolute;
  right: 0;
}
@media (min-width: 768px) {
  .speakerName {
    font-size: 1.28rem;
  }
}
@media (min-width: 992px) {
  .speakerName {
    font-size: 1.6rem;
  }
}

.limitedTime {
  font-size: 4rem;
  font-weight: 500;
  color: #048d22;
}

.limitedTimeT {
  font-size: 4rem;
  margin-top: 24px;
  text-align: center;
}

.upsellButton {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.upsellButtonDecline {
  min-height: 40px;
  margin-top: 0.5em;
}

.footer {
  background-color: #1e2629;
  margin-bottom: -8px !important;
}

.footerText {
  color: white;
  font-size: 1.9rem;
  font-weight: 400;
  margin-left: 8px;
  text-decoration: none;
}

.footerTextT {
  padding-top: 0;
  padding-bottom: 0;
}

.footerPaddingTop {
  margin-left: 0;
}

.footerLinks {
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .footerLinks {
    float: right;
  }
  .footerLinks:after {
    content: " ";
    float: none;
    clear: both;
    display: block;
  }
}

.speakerImage img {
  max-height: 265px;
  max-width: 265px;
  border-radius: 10px;
  width: 95%;
}
.speakerDetails {
  color: #4d6f7b;
  margin-top: 1em;
  text-align: center;
}

.speakersBackground::before {
  background-image: url("https://cdn.foodrevolution.org/checkout/assets/images/bg-purple-onion.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.25;
  position: absolute;
  top: 45%;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  width: 50%;
  z-index: -1;
}
@media (min-width: 1800px) {
  .speakersBackground::before {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .speakersBackground::before {
    width: 30%;
  }
}

.speakersBackground::after {
  background-image: url("https://cdn.foodrevolution.org/checkout/assets/images/bg-spinach-bowl.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  content: "";
  display: block;
  height: 100%;
  opacity: 0.25;
  position: absolute;
  right: 0;
  top: 10%;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  width: 50%;
  z-index: -1;
}
@media (min-width: 1800px) {
  .speakersBackground::after {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .speakersBackground::after {
    width: 30%;
  }
}

.upsellContainer {
  max-width: 1280px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .upsellContainer {
    width: 750px !important;
  }
}
@media (min-width: 992px) {
  .upsellContainer {
    width: 970px !important;
  }
}
@media (min-width: 1200px) {
  .upsellContainer {
    width: 1170px !important;
  }
}

.marginBottom0 {
  margin-bottom: 0 !important;
}

@media (max-width: 767px) {
  .courseContentFooter {
    text-align: center;
  }
}
.userRecipesListContainer .userRecipesListNav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  background: #f5f7fa;
  cursor: pointer;
}
.userRecipesListContainer .userRecipesListNav span {
  color: #cdd1d4;
  font-size: 1.6rem;
  padding-top: #222;
  padding-bottom: #222;
  margin-bottom: 16px;
  margin-top: 16px;
  background-clip: border-box;
}
.addressesContainer span, .addressesContainer h5 {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}

.addressesAddNewButton {
  margin-top: 24px;
}
.addressesAddNewButton button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.addressAddNewButtonBox {
  margin: 24px 0;
  text-align: center;
}
.addressAddNewButtonBox button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.userBadge {
  padding: 0px;
}
.userBadgeImg {
  border-radius: 50%;
  background-size: cover;
}

.passwordResetContainer {
  min-height: 48px;
  padding: 16px;
}
.passwordResetContainer span, .passwordResetContainer h5 {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}
.passwordResetContainer .passwordResetButton button {
  margin-right: 24px;
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.passwordResetFormInput {
  margin-bottom: 9px !important;
  background-color: #dfe3e6;
}

.passwordResetButtonsBox {
  margin-top: 24px;
  text-align: center;
}
.passwordResetButtonsBox button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.editPicContainer {
  text-align: center;
}
.editPicContainer > div > div {
  margin: 24px auto;
  overflow: hidden;
}
.editPicContainer .editPicPreviewCroppedImage {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editPicControls {
  text-align: center;
}

.editPicDropZone {
  border: 3px solid transparent;
  width: 100%;
  text-align: center;
}
.editPicDropZoneActive {
  border: 3px solid #28a745;
}
.editPicDropZonePlaceholder {
  padding: 100px 0;
}
.editPicDropZonePlaceholderTitle {
  font-size: 2.2rem;
}
.editPicDropZonePlaceholderOr {
  margin: 10px 0;
}

.passwordResetContainer {
  min-height: 48px;
  padding: 16px;
}
.passwordResetContainer span, .passwordResetContainer h5 {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}
.passwordResetContainer .passwordResetButton button {
  margin-right: 24px;
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.passwordResetFormInput {
  margin-bottom: 9px !important;
  background-color: #dfe3e6;
}

.passwordResetButtonsBox {
  margin-top: 24px;
  text-align: center;
}
.passwordResetButtonsBox button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.userPaymentMethod .row {
  align-items: center;
}

.profileSummary {
  padding-top: 64px;
  padding-bottom: 30px;
}
.profileSummary .summaryImgInfo {
  align-items: center;
}
.profileSummary .summaryInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .profileSummary .summaryInfo {
    margin-top: 0;
  }
}
.profileSummary .summaryInfo .summaryLine.profileName {
  font-size: 2.2rem;
}
@media (max-width: 991.98px) {
  .profileSummary {
    text-align: center;
  }
}
.profileSummarySocial a:first-child {
  margin-right: 5px;
}
.profileSummaryButtons button {
  margin-right: 5px;
}
@media (max-width: 767.98px) {
  .profileSummaryButtons button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.profileSummaryGoal {
  border-top: 1px solid #bacad5;
  padding-top: 15px;
}

.userAvatar {
  text-align: center;
  margin: 0 auto;
  background-color: #bacad5;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  width: 150px;
  height: 150px;
}
.userAvatar200 {
  width: 200px;
  height: 200px;
}
.userAvatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.userAvatar span {
  background: rgba(34, 34, 34, 0.5);
  bottom: 0;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 0.9rem 0;
  line-height: 1.2rem;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.userSubscriptionsCancelLink {
  color: #7d00b5;
  text-decoration: underline;
  cursor: pointer;
}

.card.upgradeBannerWrapper {
  display: flex;
  background-color: #7d00b5;
  color: white;
}

.upgradeBannerCopy {
  padding: 1.25rem;
  font-weight: bold;
}

.upgradeBannerCopy > h5 {
  color: inherit;
}

.profileItemTitle {
  margin-top: 16px;
}

.profileBig {
  margin-bottom: 128px;
  display: none;
  min-height: 2000px;
}
@media (min-width: 768px) {
  .profileBig {
    display: block;
  }
}

.profileSmall {
  margin-bottom: 128px;
  display: block;
  min-height: 2000px;
}
@media (min-width: 768px) {
  .profileSmall {
    display: none;
  }
}

.passwordLoginFormContainer .inputGroupContainer {
  margin-bottom: 12px;
}
.passwordLoginFormContainer span {
  color: #cdd1d4;
}
.passwordLoginFormContainer .passwordLoginFormInputClick {
  color: #72c9ed;
}
.passwordLoginFormContainer .passwordLoginFormInputClick:hover {
  cursor: pointer;
}
.passwordLoginFormContainer .passwordLoginFormActions {
  margin-bottom: 18px !important;
  margin-top: 32px;
}
.passwordLoginFormContainer .passwordLoginFormActions > div {
  text-align: center;
}

#fb-wrapper button {
  font-size: 1.2rem;
  background-color: #ff9400;
  padding-top: 10px;
  font-weight: 700;
  text-transform: none !important;
  height: 44px;
}
#fb-wrapper button:hover {
  background-color: #cc7600;
}

.registrationFormBack {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 18px !important;
}
.registrationFormBack span {
  color: #cdd1d4;
}
.registrationFormBack span.clickable {
  color: #72c9ed;
}
.registrationFormBack span:hover {
  cursor: pointer;
}

.resetFormContainer .resetFormBack {
  margin-top: 32px;
  margin-bottom: 18px !important;
  text-align: center;
}
.resetFormContainer .resetFormBack span {
  color: #72c9ed;
}
.resetFormContainer .resetFormBack span:hover {
  cursor: pointer;
}

.UpSellAC18 {
  background: #fff;
  font-size: 2rem;
}
.UpSellAC18 .background-dark, .UpSellAC18 .background-dark .col {
  color: #fff;
}
.UpSellAC18 h1, .UpSellAC18 h2, .UpSellAC18 h3, .UpSellAC18 h4, .UpSellAC18 h5, .UpSellAC18 h6 {
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.5em;
  margin-top: 0;
  color: #37474F;
}
.UpSellAC18 h5 {
  font-size: 2.4rem;
  color: #37474F;
}
.UpSellAC18 .text-h5 {
  font-size: 2.4rem;
}
.UpSellAC18 .text-center {
  text-align: center;
}
.UpSellAC18 .container, .UpSellAC18 .div {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.UpSellAC18 .has-triangle-bottom .container, .UpSellAC18 .has-triangle-bottom .div {
  padding-bottom: 0;
}
.UpSellAC18 .btn-wrap {
  white-space: normal;
}
.UpSellAC18 .arrow-bottom {
  margin-bottom: 50px;
  margin-top: -1px;
  position: relative;
}
.UpSellAC18 .arrow-bottom::after {
  border-left: 50px solid rgba(255, 255, 255, 0);
  border-right: 50px solid rgba(255, 255, 255, 0);
  clear: both;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  z-index: 1;
}
.UpSellAC18 .arrow-bottom-green::after {
  border-top: 50px solid #28A745;
}
.UpSellAC18 .arrow-bottom-teal::after {
  border-top: 50px solid #17a2b8;
}
.UpSellAC18 .arrow-bottom-lightpurple::after {
  border-top: 50px solid #ab08d3;
}
.UpSellAC18 .arrow-bottom-purple::after {
  border-top: 50px solid #630090;
}
.UpSellAC18 .arrow-bottom-black-25::after {
  border-top: 50px solid rgba(0, 0, 0, 0.25);
}
.UpSellAC18 .arrow-bottom-size-25 {
  margin-bottom: 25px;
}
.UpSellAC18 .arrow-bottom-size-25::after {
  border-left-width: 25px;
  border-right-width: 25px;
  border-top-width: 25px;
}
.UpSellAC18 .arrow-bottom-size-30 {
  margin-bottom: 30px;
}
.UpSellAC18 .arrow-bottom-size-30::after {
  border-left-width: 30px;
  border-right-width: 30px;
  border-top-width: 30px;
}
.UpSellAC18 .arrow-bottom-margin-fix-30 {
  margin-top: -30px;
}
.UpSellAC18 .background {
  position: relative;
}
.UpSellAC18 .background-overflow {
  overflow: visible;
}
.UpSellAC18 .background-dark {
  color: #fff;
}
.UpSellAC18 .background-dark h1, .UpSellAC18 .background-dark h2, .UpSellAC18 .background-dark h3, .UpSellAC18 .background-dark h4, .UpSellAC18 .background-dark h5, .UpSellAC18 .background-dark h6 {
  color: #fff;
}
.UpSellAC18 .background-high-blue-gray {
  background-color: #4d6f7b;
}
.UpSellAC18 .background-light-blue-gray {
  background-color: #f3f4f5;
}
.UpSellAC18 .background-green {
  background-color: #28A745;
}
.UpSellAC18 .background-mint {
  background-color: #f3f8f7;
}
.UpSellAC18 .background-deep-mint {
  background-color: #d9eeeb;
}
.UpSellAC18 .background-purple {
  background-color: #630090;
}
.UpSellAC18 .background-lightpurple {
  background-color: #ab08d3;
}
.UpSellAC18 .background-white {
  background-color: #fff;
}
.UpSellAC18 .background-teal {
  background-color: #17a2b8;
}
.UpSellAC18 .background-overlay {
  position: relative;
}
.UpSellAC18 .background-overlay::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.UpSellAC18 .background-overlay-black::before {
  background-color: rgba(0, 0, 0, 0.05);
}
.UpSellAC18 .background-overlay-black-90::before {
  background-color: rgba(0, 0, 0, 0.9);
}
.UpSellAC18 .background-overlay-black-75::before {
  background-color: rgba(0, 0, 0, 0.75);
}
.UpSellAC18 .background-overlay-black-50::before {
  background-color: rgba(0, 0, 0, 0.5);
}
.UpSellAC18 .background-overlay-black-25::before {
  background-color: rgba(0, 0, 0, 0.25);
}
.UpSellAC18 .background-overlay-white-90::before {
  background-color: rgba(255, 255, 255, 0.9);
}
.UpSellAC18 .background-overlay-white-75::before {
  background-color: rgba(255, 255, 255, 0.75);
}
.UpSellAC18 .background-overlay-white-50::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.UpSellAC18 .background-overlay-white-25::before {
  background-color: rgba(255, 255, 255, 0.25);
}
.UpSellAC18 .background-overlay-purple::before {
  background-color: rgba(99, 0, 144, 0.6);
}
@media (min-width(var(--breakpoint-sm))) {
  .UpSellAC18 .background-overlay-xs::before {
    display: none;
  }
}
@media (min-width(var(--breakpoint-md))) {
  .UpSellAC18 .background-overlay-sm::before {
    display: none;
  }
}
@media (min-width(var(--breakpoint-lg))) {
  .UpSellAC18 .background-overlay-md::before {
    display: none;
  }
}
.UpSellAC18 .background-overlay-gradient-purple-x::before {
  background: linear-gradient(rgba(171, 8, 211, 0.8), rgba(99, 0, 144, 0.8));
}
.UpSellAC18 .background-overlay-angle::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width(var(--breakpoint-sm))) {
  .UpSellAC18 .background-overlay-angle::before {
    width: 65%;
  }
}
@media (min-width(var(--breakpoint-sm))) {
  .UpSellAC18 .background-overlay-angle-left::before {
    left: -10%;
    transform: skewX(-15deg);
  }
}
@media (min-width(var(--breakpoint-sm))) {
  .UpSellAC18 .background-overlay-angle-right::before {
    left: initial;
    right: -10%;
    transform: skewX(15deg);
  }
}
.UpSellAC18 .background-gradient-purple {
  background: linear-gradient(to right, #630090, #ab08d3);
}
.UpSellAC18 .background-gradient-mint-y {
  background: linear-gradient(#fff, #f3f8f7);
}
.UpSellAC18 .background-gradient-deep-mint-y {
  background: linear-gradient(#fff, #d9eeeb);
}
.UpSellAC18 .background-gradient-deep-mint-yb {
  background: linear-gradient(#d9eeeb, #fff);
}
.UpSellAC18 .background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.UpSellAC18 .background-image-fixed {
  background-attachment: fixed;
}
.UpSellAC18 .background-image-overlay-gradient-white-right::before {
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media (min-width(var(--breakpoint-md))) {
  .UpSellAC18 .background-image-overlay-gradient-white-right::before {
    background: linear-gradient(to right, #fff 50%, rgba(255, 255, 255, 0) 80%);
  }
}
.UpSellAC18 .background-image-fruits {
  background-image: url("https://cdn.foodrevolution.org/checkout/upsells/ac18/ac18-section-bg-fruits.jpg");
}
.UpSellAC18 .background-image-fruit-veggies {
  background-image: url("https://cdn.foodrevolution.org/checkout/upsells/ac18/ac18-bg-fruit-veggies.jpg");
}
.UpSellAC18 .background-image-trees-white {
  background-image: url("https://cdn.foodrevolution.org/checkout/upsells/ac18/bg-trees-white.jpg");
  background-position: center bottom;
}
.UpSellAC18 .background-image-trees-white::before {
  background: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 90%, #fff 100%);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.UpSellAC18 .background-image-woman-salad {
  background-image: url("https://cdn.foodrevolution.org/checkout/upsells/ac18/wlc-bg-woman-salad.jpg");
  background-position: right bottom;
  background-size: contain;
}
.UpSellAC18 .fill-curve {
  height: 50px;
  overflow: hidden;
}
.UpSellAC18 .fill-curve-top {
  margin-top: -50px;
}
.UpSellAC18 .fill-curve-padding-bottom {
  padding-bottom: 50px;
}
.UpSellAC18 .fill-svg {
  height: 100%;
  width: 100%;
}
.UpSellAC18 .fill-path {
  stroke: none;
}
.UpSellAC18 .fill-mint {
  fill: #f3f8f7;
}
.UpSellAC18 .fill-purple-gradient {
  fill: url("#purple-gradient");
}
.UpSellAC18 .img-trees-future {
  max-height: 115px;
}
.UpSellAC18 .center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.UpSellAC18 .btn-orange {
  background-color: #ff9400;
  color: #fff;
}
.UpSellAC18 .btn-grey {
  background-color: #AAA;
  color: #fff;
}
.UpSellAC18 .btn-box-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
}
.UpSellAC18 .what-you-get img {
  max-height: 300px;
}
.UpSellAC18 .img-circle {
  border-radius: 50%;
}
.UpSellAC18 .img-responsive {
  display: block;
  width: 100%;
  height: auto;
}
.UpSellAC18 .section-speakers .speaker-list {
  justify-content: center;
}
.UpSellAC18 .section-speakers .speaker-list .col {
  flex: 0 0 50%;
  max-width: 50%;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 992px) {
  .UpSellAC18 .section-speakers .speaker-list .col {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media (min-width: 1200px) {
  .UpSellAC18 .section-speakers .speaker-list .col {
    flex-basis: 20%;
    max-width: 20%;
  }
}
.UpSellAC18 .section-speakers .speaker-list .col img {
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .UpSellAC18 .section-speakers {
    padding: 0 !important;
  }
  .UpSellAC18 .section-speakers .speaker-list .col {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.UpSellAC18 .background-dark a, .UpSellAC18 .background-dark a:hover {
  color: #fff;
}

.EPNav ul {
  border-bottom: thin solid grey;
  margin-bottom: 18px;
}
.EPNav li {
  list-style: none;
  display: inline-block;
  margin-left: 15px;
}

.btn-secondary {
  background: #ff9400;
  max-width: 100%;
  white-space: normal;
}

.interviewCollectionWrapper {
  margin-top: 15px;
  margin-bottom: 30px;
  padding: 10px;
}

.IC-bio {
  padding: 0px;
  position: relative;
}
.IC-bio:hover {
  text-decoration: none;
}
.IC-bio:first-of-type {
  border-top-left-radius: 15px;
}
.IC-bio:first-of-type img {
  border-top-left-radius: 15px;
}
.IC-bio:last-of-type {
  border-top-right-radius: 15px;
}
.IC-bio:last-of-type img {
  border-top-right-radius: 15px;
}
.IC-bio img {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.IC-heading {
  font-size: 28px;
  color: #440062;
  font-weight: bold;
}
.IC-link {
  margin-top: 20px;
}
.IC-bioname {
  background: green;
  height: 80px;
  padding: 2px;
  position: absolute;
  margin-top: -80px;
  font-size: 14px;
  width: 100%;
  color: white;
  opacity: 0.8;
  text-align: center;
  overflow: hidden;
  line-height: 20px;
}
.IC-bioname:hover {
  font-weight: bold;
}
.IC-vertical-center {
  height: 40px;
  margin-top: 20px;
}
.IC-nav {
  padding: 0px;
  border-bottom: thin solid grey;
  height: 30px;
  margin-left: 0px;
}
.IC-nav:after {
  clear: both;
}

.IC-nav li {
  float: left;
  margin-left: 20px;
  list-style-type: none;
  padding: 5px;
}
.IC-nav li:first-of-type {
  margin-left: 5px;
}

.IC-nav ul {
  margin-left: 0px;
  padding: 0px;
}

.wlcNavContainer {
  background: #ffffff;
}
@media (max-width: 767.98px) {
  .wlcNavContainer .nav-item {
    margin-left: 22px;
    cursor: pointer;
  }
  .wlcNavContainer .nav-item.active {
    display: none;
  }
  .wlcNavContainer .navbar-brand, .wlcNavContainer .navbar-light-brand {
    height: 6.5rem;
    font-size: 1.6rem;
    color: #007bff !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  .wlcNavContainer .navbar-toggler, .wlcNavContainer .navbar-light-toggler {
    border: none;
  }
}
@media (min-width: 576px) {
  .wlcNavContainer .navbar {
    padding: 0;
    height: 6.5rem;
  }
  .wlcNavContainer .navbar-brand {
    display: none;
  }
  .wlcNavContainer .navbar-collapse {
    height: 100%;
  }
  .wlcNavContainer .navbar-nav {
    width: 100%;
    height: 100%;
  }
  .wlcNavContainer .nav-item {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    cursor: pointer;
  }
  .wlcNavContainer .nav-item.active {
    border-bottom: 2px solid #007bff;
  }
  .wlcNavContainer .nav-link {
    cursor: pointer;
  }
  .wlcNavContainer .navbar-light .navbar-nav .active > .nav-link {
    color: #007bff;
  }
}
@media print {
  .wlcNavContainer {
    display: none;
  }
}

.wlcVideoContainer {
  background: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
  padding: 35px 20px;
}
@media (max-width: 575.98px) {
  .wlcVideoContainer {
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 30px);
    padding-right: 30px;
    padding-left: 30px;
  }
}
.wlcVideoContainer h4 {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  margin-bottom: 2rem;
}

/* Rectangle 2: */
.welcome-container p {
  font-size: 2rem;
  margin-bottom: 20px;
}

.welcome-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin-top: 20px;
  padding: 5px;
  border-radius: 8px;
  padding-top: 0;
  /* background-color: #f9f9f9; */
}

.welcome-container h1 {
  color: #333;
}

.welcome-container h3 {
  margin-top: 20px;
}

.progress-image img {
  width: 40px;
  max-width: 40px;
  cursor: pointer;
}

/* media query for small screens */
@media screen and (max-width: 767px) {
  .progress-image img {
    width: 30px;
    max-width: 30px;
  }
}
.social-proof-wrapper {
  background-color: #7D00B5;
  color: white;
  border: 1px solid #ccc;
  /* border-radius: 15px; */
}

.social-proof-wrapper h4 {
  color: white;
}

.social-proof {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add this to separate the image and quote */
  padding: 20px;
  margin-top: 20px;
  background-color: #7D00B5;
  color: white;
}

.social-proof img {
  max-width: 100px;
  width: 100px;
  height: 100px;
  margin-right: 20px;
  border-radius: 50%;
  border: 3px solid white;
}

.social-proof blockquote {
  margin: 0;
  flex: 1; /* Allow the quote to expand and take available space */
}

.social-proof blockquote p {
  font-style: italic;
  font-size: 20px;
}

.social-proof blockquote footer {
  text-align: right;
  padding-top: 10px;
}

.staff-picks {
  /* Center the profile picture and staff name */
  /* Adjust card spacing */
  /* Increase space between staff name and profile pic */
  /* Style staff review */
}
.staff-picks .profile-pic {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.staff-picks .card {
  border: none;
  transition: transform 0.2s;
}
.staff-picks .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.staff-picks .card-body .ml-2 {
  margin-left: 8px;
}
.staff-picks .card-text {
  height: 80px; /* Limit description height */
  overflow: y-scroll;
  /* scroll */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.staff-picks .font-italic {
  color: #888;
}
.staff-picks .aspect-ratio-16by9 {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 9/16 * 100% */
}
.staff-picks .aspect-ratio-16by9 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will ensure the image covers the entire space and maintains aspect ratio */
}

.onboarding .list {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between items */
}

.onboarding .o-list-item {
  display: flex;
  flex-direction: row; /* Change to row */
  align-items: center; /* Align items vertically */
  gap: 20px; /* Add spacing between image and content */
}

.onboarding .image {
  width: 65px;
  height: 65px;
  margin-left: 3px;
}

.onboarding .image img {
  width: 65px;
  height: 65px;
  object-fit: cover;
}

.onboarding .o-content {
  height: 100;
  align-items: center;
  text-align: left;
}

.onboarding .o-title {
  justify-self: flex-start;
  font-size: 2rem;
  margin: 0;
}

.onboarding .o-description {
  justify-self: flex-start;
}

.o-not-selected {
  border: 3px solid #e6e6e6;
  border-radius: 20px;
}

.o-selected {
  /* background-color: #e6e6e6; */
  border: 3px solid #048D22;
  border-radius: 20px;
}

/* media query for mobile */
@media screen and (max-width: 768px) {
  .onboarding .list {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
  .onboarding .o-list-item {
    flex-direction: column;
    gap: 10px;
  }
  .onboarding .o-content {
    text-align: center;
    padding: 5px;
    padding-top: 0px;
  }
  .onboarding .o-title {
    justify-self: center;
  }
  .onboarding .o-description {
    justify-self: center;
  }
}
.audioPlayerMobileControlsContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: #8493a8;
}
.audioPlayerMobileControlsContainer > div {
  line-height: 3;
  cursor: pointer;
}
.audioPlayerMobileControlsContainer .apmcButton {
  padding-top: 1px;
  margin: 0 auto;
  background-color: #e91e63;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  text-align: center;
  line-height: 30px;
}
.audioPlayerMobileControlsContainer .apmcButton svg {
  color: white !important;
  height: 15px !important;
  padding-top: 6px;
  padding-left: 2px;
}

.apmDownloadsList {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
  color: #222;
}
.apmDownloadsList a {
  color: #222;
}
.apmDownloadsList svg {
  font-size: 32px;
}
.apmDownloadsList span {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.audioPlayerMobileHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px !important;
}
.audioPlayerMobileHeaderContainer .apmhOne {
  border-radius: 8px;
  width: 85px;
  height: 85px;
  min-width: 85px;
  min-height: 85px;
  background: lightgrey;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.audioPlayerMobileHeaderContainer .apmhTwo {
  width: 100%;
  padding-left: 10px;
  line-height: 1.2;
}
.audioPlayerMobileHeaderContainer .apmhTwo .apmhInterviewee {
  text-align: left;
  margin-top: 0.75rem;
}
.audioPlayerMobileHeaderContainer .apmhThree {
  flex-basis: 20%;
  margin-top: 0.75rem;
  text-align: right;
  min-width: 90px;
}
.audioPlayerMobileHeaderContainer .apmhThree svg {
  cursor: pointer;
  margin-left: 12px;
  transform: scale(1.33);
}
.audioPlayerMobileHeaderContainer .apmhThree div {
  color: #e91e63;
}
@media (max-width: 575.98px) {
  .audioPlayerMobileHeaderContainer .amphFullTitle {
    font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.6rem;
  }
}

.audio-container .progressBarContainer {
  display: flex;
  align-items: center;
  color: #8493a8;
}
.audio-container .progress {
  background-color: #ffffff;
  border: 1px solid #dfe3e6;
  cursor: pointer;
}
.audio-container .progress > div {
  background-color: transparent !important;
  background-image: linear-gradient(to right, #ff9a48, #ca3058);
}
.audio-container .progress .progress-time {
  padding: 0 8px;
}

.audioPlayerMobileTextContainer {
  margin-top: 1.5rem;
  text-align: left;
}
.audioPlayerMobileTextContainer > div {
  margin-bottom: 8px;
}
.audioPlayerMobileTextContainer span {
  font-size: 1.4rem;
}

.apmPictureContainer {
  max-width: 150px;
}

.bonusesListItem {
  color: #444;
  border: none;
  margin-bottom: 18px;
}
.bonusesListItem img {
  width: 100%;
  height: auto;
}
.bonusesListItem h3 {
  margin-top: 16px;
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  max-width: 66%;
  text-transform: none;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem h3 {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem > span {
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem > span {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem .bonusesListItemDetails {
  margin-bottom: 14.4px !important;
  margin-top: 25.6px;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem .bonusesListItemDetails {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem > div {
  margin-bottom: 32px;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem > div {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem > div a {
  color: #444;
}
.bonusesListItem > div a:hover {
  text-decoration: none;
}
.bonusesListItem > div a .bonusesListItemLearnMore {
  margin-bottom: 14.4px !important;
}
.bonusesListItem > div a > div {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}
.bonusesListItem > div a > div > span span {
  padding-right: 26px;
}

.listItem {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  border: none;
  box-sizing: content-box;
  border-bottom: 2px solid #fff !important;
}
.listItem .hidden {
  display: none;
}
.listItem .center1 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 33%;
}
.listItem .center2 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 66%;
}
.listItem h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  color: white;
  padding: 0;
}
.listItem p {
  color: white;
}
.listItem img {
  width: 100%;
  height: auto;
}

.listItem {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  border: none;
  box-sizing: content-box;
  border-bottom: 2px solid #fff !important;
}
.listItem .hidden {
  display: none;
}
.listItem .center1 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 33%;
}
.listItem .center2 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 66%;
}
.listItem .center3 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 75%;
}
.listItem h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  color: white;
  padding: 0;
}
.listItem p {
  color: white;
}
.listItem img {
  width: 100%;
  height: auto;
}

.checklistItem {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px 16px;
  margin-bottom: 16px;
  cursor: pointer;
}
.checklistItem > div {
  display: inline-block;
}
.checklistItem .checklistItemCheckBox {
  padding-right: 16px;
}
.checklistItem .checklistItemCheckBox svg {
  width: 2.4rem;
  height: 2.4rem;
}

.checklistItemV2 {
  font-size: 18px;
}

.checklistItem {
  display: flex;
  align-items: center;
  background-color: #fafaff;
  padding: 8px 16px;
  margin-bottom: 16px;
  cursor: pointer;
}
.checklistItem > div {
  display: inline-block;
}
.checklistItem .checklistItemCheckBox {
  padding-right: 16px;
}
.checklistItem .checklistItemCheckBox svg {
  width: 2.4rem;
  height: 2.4rem;
}

.listItem {
  margin-bottom: 18px !important;
  margin-top: 32px;
  border: none;
}
.listItem .collectionListItemContentTitle {
  margin-bottom: 9px !important;
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .listItem .collectionListItemContentTitle {
    margin-left: 0;
    margin-right: 0;
  }
}

.bonusesListItem {
  color: #444;
  border: none;
  margin-bottom: 18px;
}
.bonusesListItem img {
  width: 100%;
  height: auto;
}
.bonusesListItem h3 {
  margin-top: 16px;
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  max-width: 66%;
  text-transform: none;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem h3 {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem > span {
  margin-bottom: 18px !important;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem > span {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem .bonusesListItemDetails {
  margin-bottom: 14.4px !important;
  margin-top: 25.6px;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem .bonusesListItemDetails {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem > div {
  margin-bottom: 32px;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .bonusesListItem > div {
    margin-left: 0;
    margin-right: 0;
  }
}
.bonusesListItem > div a {
  color: #444;
}
.bonusesListItem > div a:hover {
  text-decoration: none;
}
.bonusesListItem > div a .bonusesListItemLearnMore {
  margin-bottom: 14.4px !important;
}
.bonusesListItem > div a > div {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}
.bonusesListItem > div a > div > span span {
  padding-right: 26px;
}

.div {
  padding: 0;
}

.listItem {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  border: none;
  box-sizing: content-box;
  border-bottom: 2px solid #fff !important;
}
.listItem .hidden {
  display: none;
}
.listItem .center1 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 33%;
}
.listItem .center2 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 66%;
}
.listItem h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  color: white;
  padding: 0;
}
.listItem p {
  color: white;
}
.listItem img {
  width: 100%;
  height: auto;
}

.wlcOnboardCommunityGuidelines h4 {
  margin-bottom: 2rem;
  text-align: center;
}
.wlcOnboardCommunityGuidelines h5 {
  margin-top: 2rem;
  text-decoration: underline;
}

.wlcOnboardCommunityGuidelines h4 {
  margin-bottom: 2rem;
  text-align: center;
}
.wlcOnboardCommunityGuidelines h5 {
  margin-top: 2rem;
  text-decoration: underline;
}

.recipeDirectionsContainer {
  padding: 3rem;
}
@media (max-width: 575.98px) {
  .recipeDirectionsContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.recipeNotesContainer {
  padding: 3rem;
}
@media (max-width: 575.98px) {
  .recipeNotesContainer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media print {
  .recipeNotesContainer {
    padding: 0;
  }
}

.recipeStarsContainer {
  display: flex;
  margin-bottom: 12px;
  justify-content: center;
  font-size: 2.4rem;
}
.recipeStarsContainer [class|=star] {
  width: 16px;
  height: 16px;
  margin: 0 2px;
  cursor: pointer;
  background: url("../images/star/star-filled.png") 0 0px no-repeat;
  background-size: contain;
}
.recipeStarsContainer [class|=star][class$=-unfilled] {
  background-size: contain !important;
  background: url("../images/star/star-unfilled.png") 0 0px no-repeat;
}
@media (min-width: 992px) {
  .recipeStarsContainer {
    margin-bottom: 16px;
    justify-content: flex-end;
  }
  .recipeStarsContainer [class|=star] {
    width: 16px;
    height: 16px;
    margin: 0 2px;
    cursor: pointer;
    background: url("../images/star/star-filled.png") 0 0px no-repeat;
    background-size: contain;
  }
  .recipeStarsContainer [class|=star][class$=-unfilled] {
    background-size: contain !important;
    background: url("../images/star/star-unfilled.png") 0 0px no-repeat;
  }
}
.recipeStarsContainer svg {
  cursor: pointer;
}

.resourcesListItem {
  border: none;
  border-radius: 0;
  max-height: 120px;
}
.resourcesListItem > div {
  min-width: 160px;
  position: relative;
  height: 120px;
  max-height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.resourcesListItem > div > h4 {
  color: white;
  position: absolute;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  top: 46%;
  right: 18%;
  margin: auto;
  width: 66%;
  font-size: 14px;
  font-weight: small;
}

.sessionResource {
  margin-bottom: 24px !important;
}
.sessionResource .sessionResourceTitle {
  padding-left: 0 !important;
  margin-left: 26px;
}
.sessionResource .sessionResourceTitleIcon {
  margin-right: 13px;
  display: inline-block;
}
.sessionResource .sessionResourceRowOne {
  cursor: pointer;
}
.sessionResource .sessionResourceTitleText {
  display: inline-block;
}
.sessionResource h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  margin-bottom: 9px !important;
  margin-top: 32px;
  margin-left: 26px;
  margin-right: 26px;
}
@media (min-width: 576px) {
  .sessionResource h5 {
    margin-left: 0;
    margin-right: 0;
  }
}

.specialReportItem {
  margin-bottom: 54.5454545455px !important;
}
.specialReportItem span {
  display: block;
}
.specialReportItem > img {
  width: 100%;
  max-width: 250px;
  height: auto;
  margin-bottom: 9px !important;
}
.specialReportItem .specialReportsSpacing {
  margin-bottom: 18px !important;
}
.specialReportItem > h5 {
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
  text-transform: none;
}

.surveyResultsContainer {
  margin-top: 32px;
  margin-bottom: 18px !important;
}

.surveyResultsHeadline {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  margin-top: 16px;
  margin-bottom: 9px !important;
  padding-left: 0;
}

.surveyResultsQuestion {
  margin-bottom: 9px !important;
}

.surveyResultsQuestionText {
  text-align: center;
  background-color: #048d22;
  padding: 26px;
  color: white;
}
.surveyResultsQuestionText div {
  margin-right: 26px;
  margin-left: 26px;
}

.surveyResultsAnswers {
  text-align: center;
  padding: 26px;
}
.surveyResultsAnswers span {
  display: block;
}
.surveyResultsAnswers span:nth-of-type(2) {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  font-weight: 900;
  color: #048d22;
}

.surveyResultsContainer {
  margin-top: 32px;
  margin-bottom: 18px !important;
}

.surveyResultsHeadline {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
  margin-top: 16px;
  margin-bottom: 9px !important;
  padding-left: 0;
}

.surveyResultsQuestion {
  margin-bottom: 9px !important;
}

.surveyResultsQuestionText {
  text-align: center;
  background-color: #048d22;
  padding: 26px;
  color: white;
}
.surveyResultsQuestionText div {
  margin-right: 26px;
  margin-left: 26px;
}

.surveyResultsAnswers {
  text-align: center;
  padding: 26px;
}
.surveyResultsAnswers span {
  display: block;
}
.surveyResultsAnswers span:nth-of-type(2) {
  text-transform: uppercase;
  color: #444;
  font-size: 1.6rem;
  font-weight: 400;
  font-weight: 900;
  color: #048d22;
}

.toolsCardContainer {
  padding: 26px;
  margin-bottom: 24px !important;
}
.toolsCardContainer > h5 {
  font-size: 14px;
  text-transform: uppercase;
  color: #444;
}
.toolsCardContainer > img, .toolsCardContainer a, .toolsCardContainer span {
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 600px;
}
.toolsCardContainer > span {
  margin-bottom: 9px !important;
}
.toolsCardContainer > a, .toolsCardContainer img {
  margin-bottom: 4.5px !important;
}

.wlcWeekNavContainer {
  margin-top: 24px;
  z-index: 998;
  position: relative;
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
}

.wlcWeekOf {
  text-align: right;
}
@media (max-width: 767.98px) {
  .wlcWeekOf {
    text-align: left;
  }
}

.wlcWeekNavSelect {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.wlcWeekNavSelect > div {
  padding: 12px;
  min-width: 275px;
  height: 120px;
  background-color: #fff;
  z-index: 1200;
  max-width: 225px;
  transform: translate(2px, -16px);
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}
.wlcWeekNavSelect > div a, .wlcWeekNavSelect > div span {
  color: #048d22;
}

.orderProductsContainer {
  margin: 8px 0;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  margin: 6px 0 16px 0;
}
@media print {
  .orderProductsContainer {
    display: block;
  }
}

.orderListItemContainer {
  line-height: 1em;
  margin-bottom: 16px;
  padding: 16px 16px 16px 16px;
}
.orderListItemContainer .orderListSummaryRow {
  display: flex;
  justify-content: space-between;
}
.orderListItemContainer .orderListSummaryRow .orderListOrderPlaced {
  text-align: left;
  max-width: 50%;
  order: -10;
}
@media (min-width: 576px) {
  .orderListItemContainer .orderListSummaryRow .orderListOrderPlaced {
    flex-basis: unset;
    order: unset;
  }
}
.orderListItemContainer .orderListSummaryRow .orderListOrderTotal {
  margin-top: 16px;
  text-align: right;
  order: 30;
}
@media (min-width: 576px) {
  .orderListItemContainer .orderListSummaryRow .orderListOrderTotal {
    text-align: left;
    margin-top: 0;
    order: 0;
  }
}
.orderListItemContainer .orderListSummaryRow .orderListOrderNum {
  text-align: right;
  flex-basis: 50% !important;
  order: -1;
}
@media (min-width: 576px) {
  .orderListItemContainer .orderListSummaryRow .orderListOrderNum {
    flex-basis: unset;
    order: unset;
  }
}
.orderListItemContainer .orderListSummaryRow .orderListOrderName {
  margin-top: 16px;
}
.orderListItemContainer .orderListItemOrderName {
  cursor: pointer;
}
.orderListItemContainer .orderListItemOrderName span {
  color: #048d22;
  font-size: 18px;
  text-decoration: underline;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
}
@media print {
  .orderListItemContainer .orderListItemOrderName span {
    color: black;
    text-decoration: none;
  }
}
.orderListItemContainer .orderListOrderItemProductName {
  margin-bottom: 4px;
  padding-left: 4px;
}
.orderListItemContainer .orderListOrderViewDetails {
  margin-top: 16px;
}
@media print {
  .orderListItemContainer .orderListOrderViewDetails {
    display: none;
  }
}
@media print {
  .orderListItemContainer {
    border-bottom: 1px solid black;
  }
  .orderListItemContainer:last-of-type {
    border-bottom: none;
  }
  .orderListItemContainer body {
    color: black;
  }
}

.editUserInfoContainer {
  padding: 16px;
}
.editUserInfoContainer.-onboard {
  padding: 8px;
}
.editUserInfoContainer .editUserInfoButtons {
  margin-top: 24px;
  text-align: center;
}
.editUserInfoContainer .editUserInfoButtons button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}
.editUserInfoContainer .editUserPicButton {
  margin-top: 24px;
  min-height: 64px;
  display: flex;
  justify-content: space-between;
}
.editUserInfoContainer .editUserPicButton button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.editUserInfo {
  min-height: 200px;
}

.editUserInfoFormInput {
  margin-bottom: 9px !important;
  background-color: #dfe3e6;
}

.editUserPicModalContainer {
  padding: 16px;
}
.editUserPicModalContainer .editUserPicModalButton {
  display: flex;
  justify-content: space-around;
}
.editUserPicModalContainer .editUserPicModalButton button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.isOnboardEditUserTitle {
  border-bottom: 2px solid #f5f7fa;
  padding-bottom: 12px;
  margin-bottom: 16px;
}
.isOnboardEditUserTitle span {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.userPersonalInfoContainer {
  margin-bottom: 18px !important;
  padding: 16px;
  margin-top: 0 !important;
}
.userPersonalInfoContainer .userPersonalInfoButton {
  margin-top: 16px;
}
.userPersonalInfoContainer .userPersonalInfoButton button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}
.userPersonalInfoContainer .userPersonalInfoLine {
  margin-bottom: 8px;
}
.userPersonalInfoContainer .userPersonalInfoLine span {
  color: purple;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}
.userPersonalInfoContainer .userPersonalInfoTitle span {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  color: #868E96;
}

.cancelModalContainer {
  padding: 16px;
}
.cancelModalContainer span {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}
.cancelModalContainer .cancelModalPrompt {
  margin-bottom: 16px;
}
.cancelModalContainer .cancelModalButtons button {
  margin-right: 16px;
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.profileBraintreeFormContainer {
  padding: 0;
}
.profileBraintreeFormContainer .profileBraintreeFormField {
  height: 24px;
  margin-left: 12px;
  border-bottom: 1px solid lightgrey;
}
.profileBraintreeFormContainer .profileBraintreeFormWarning {
  margin-left: 16px;
  margin-bottom: 8px;
}
.profileBraintreeFormContainer .profileBraintreeFormWarning span {
  color: #a94442;
}

.updateModalContainer {
  padding: 16px;
}
.updateModalContainer span {
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
}
.updateModalContainer .updateModalUserPaymentMethod {
  padding: 8px 16px 16px 16px;
  margin-bottom: 16px;
}
.updateModalContainer .updateNewModalButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.updateModalContainer .updateNewModalButtons button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}
.updateModalContainer .updateExistingModalButtons button {
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.updateModalStateContainer {
  padding: 16px;
}
.updateModalStateContainer .updateModalStateButtons button {
  margin-right: 16px;
  border-radius: 0;
  padding: 8px unset;
  min-width: 140px;
  font-family: "proxima-nova", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
}

.ICCircleImage {
  width: 45px;
  height: 45px;
}

.ICCircleImage img {
  width: 45px;
  border-radius: 50%;
}

.InterviewCategory:hover {
  cursor: pointer;
}

.InterviewCategorySpeakerName:hover {
  text-decoration: underline;
}

.accordionPageHeader {
  border-top: 2px solid lightgrey;
  line-height: 3;
  text-align: center;
  cursor: pointer;
}

.seperator {
  height: 30px;
  width: 1px;
  background-color: white;
}

.shadowGlobal {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
}

.shadow1 {
  margin: 40px;
  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30);
  -ms-filter: "progid:DXImageTransform.Microsoft.Blur(PixelRadius=3,MakeShadow=true,ShadowOpacity=0.30)";
  zoom: 1;
}

.zIndex100 {
  z-index: 100 !important;
}

.float-discalimer {
  cursor: pointer;
}

.float-discalimer:hover {
  text-decoration: underline;
}

.carouselContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.carouselContainer {
  margin: 0;
  position: relative;
}
.carouselContainer ul {
  padding: 0;
}
.carouselContainer.row {
  margin-left: -15px;
  margin-right: -15px;
}
@media (max-width: 575.98px) {
  .carouselContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: -30px;
    margin-right: -30px;
  }
  .carouselContainer li:first {
    padding-left: 15px;
  }
  .carouselContainer .col, .carouselContainer .col-auto {
    padding-left: 0;
    padding-right: 0;
  }
}
.carouselContainer .carouselNav {
  list-style: none;
  margin: 0;
}
.carouselContainer .carouselNav .btn, .carouselContainer .carouselNav .frn-chat .amplify-tabs .amplify-button, .frn-chat .amplify-tabs .carouselContainer .carouselNav .amplify-button {
  position: absolute;
  top: 0;
  height: 100%;
  width: 40px;
  font-size: 40px;
}
.carouselContainer .carouselNav .btn svg, .carouselContainer .carouselNav .frn-chat .amplify-tabs .amplify-button svg, .frn-chat .amplify-tabs .carouselContainer .carouselNav .amplify-button svg {
  transform: "scale(1, 1.62)";
}
.carouselContainer .carouselNav-next {
  right: 0;
}
.carouselContainer .carouselNav-prev {
  left: 0;
}
.carouselContainer .carouselNav .btn, .carouselContainer .carouselNav .frn-chat .amplify-tabs .amplify-button, .frn-chat .amplify-tabs .carouselContainer .carouselNav .amplify-button {
  color: var(--light);
  transition: 250ms background-color linear, 250ms color linear, 250ms opacity linear;
}
.carouselContainer .carouselNav .btn:disabled, .carouselContainer .carouselNav .frn-chat .amplify-tabs .amplify-button:disabled, .frn-chat .amplify-tabs .carouselContainer .carouselNav .amplify-button:disabled {
  opacity: 0;
}
.carouselContainer:hover .carouselNav .btn:not(:disabled), .carouselContainer:hover .carouselNav .frn-chat .amplify-tabs .amplify-button:not(:disabled), .frn-chat .amplify-tabs .carouselContainer:hover .carouselNav .amplify-button:not(:disabled) {
  opacity: 1;
}
.carouselContainer .btn:focus, .carouselContainer .frn-chat .amplify-tabs .amplify-button:focus, .frn-chat .amplify-tabs .carouselContainer .amplify-button:focus {
  box-shadow: none;
}
.carouselContainer .col, .carouselContainer .col-auto {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.carouselContainer .col ul, .carouselContainer .col-auto ul {
  display: grid;
  grid-auto-flow: column;
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior-x: none;
  grid-auto-columns: 270px;
  grid-template-rows: repeat(1, -webkit-max-content);
  grid-template-rows: repeat(1, max-content);
  grid-column-gap: 20px;
  user-select: none;
}
@media (min-width: 576px) {
  .carouselContainer .col ul, .carouselContainer .col-auto ul {
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    grid-auto-columns: calc((100% - 40px) / 3);
  }
}
@media (min-width: 992px) {
  .carouselContainer .col ul, .carouselContainer .col-auto ul {
    grid-auto-columns: calc((100% - 60px) / 4);
  }
}
@media (min-width: 1200px) {
  .carouselContainer .col ul, .carouselContainer .col-auto ul {
    grid-auto-columns: calc((100% - 80px) / 5);
  }
}
.carouselContainer .col ul::-webkit-scrollbar, .carouselContainer .col-auto ul::-webkit-scrollbar {
  display: none;
}
.carouselContainer .col ul li, .carouselContainer .col-auto ul li {
  scroll-snap-align: start;
  cursor: pointer;
}
.carouselContainer .col .description, .carouselContainer .col-auto .description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.carouselContainer .card-img, .carouselContainer .card-img-top {
  aspect-ratio: 1.5;
  object-fit: cover;
}

@media (min-width: 576px) {
  .carousel-hero .carouselContainer ul {
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    grid-auto-columns: calc((100% - 40px) / 3);
  }
}
@media (min-width: 1200px) {
  .carousel-hero .carouselContainer ul {
    grid-auto-columns: calc((100% - 60px) / 4);
  }
}

.carousel-light .carouselNav .btn, .carousel-light .carouselNav .frn-chat .amplify-tabs .amplify-button, .frn-chat .amplify-tabs .carousel-light .carouselNav .amplify-button {
  color: var(--dark);
}

.videoModal {
  width: calc((95vh - 6rem) / 9 * 16);
  max-width: 95vw;
  position: relative;
  padding-top: 3rem;
}
.videoModal .modal-content {
  background-color: transparent;
}
.videoModal-backdrop.show {
  opacity: 0.9;
  background-color: #000;
}
.videoModalClose.btn-link {
  position: absolute;
  top: -4rem;
  left: 0;
  text-decoration: none;
  font-size: 2rem;
  color: #DDD;
}
.videoModalClose.btn-link:hover {
  text-decoration: none;
  color: #BBB;
}

.audioModal .modal-content {
  aspect-ratio: 16/9;
}

.heroHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 75px;
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  max-width: 1700px;
  margin: 0 auto;
}
.heroHeader.hasFullScreenVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
@media (min-width: 576px) {
  .heroHeader {
    height: 60vw;
    min-height: 60vh;
    display: block;
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  .heroHeader {
    height: 45vw;
    min-height: unset;
  }
}
.heroHeaderBody {
  align-self: flex-end;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 576px) {
  .heroHeaderBody {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.heroHeaderBody p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  overflow-y: "scroll";
}
.heroHeaderBody p::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.heroHeaderBody p::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.heroHeader h1,
.heroHeader h2,
.heroHeader h3,
.heroHeader h4,
.heroHeader h5,
.heroHeader h6 {
  color: var(--light);
  margin-top: 0;
}
.heroHeaderLogo {
  position: relative;
  margin: 40px 0 0 0;
  filter: drop-shadow(0 5px 25px rgba(0, 0, 0, 0.5));
  text-align: center;
  width: 100%;
  left: 0;
  z-index: 3;
}
.heroHeaderLogo img {
  max-height: 100px;
}
@media (min-width: 992px) {
  .heroHeaderLogo img {
    max-height: 150px;
  }
}
@media (min-width: 576px) {
  .heroHeaderLogo {
    position: absolute;
    margin: 40px 0 0 40px;
    text-align: left;
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.4));
    width: auto;
    max-width: 33vw;
  }
}
.heroHeaderBackground {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
}
.heroHeaderBackgroundVideo > div {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.heroHeaderBackgroundVideo iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .heroHeaderBackgroundVideo iframe {
    height: 56.25vw;
  }
}
@media (max-width: 767.98px) {
  .heroHeaderBackgroundVideo iframe {
    width: 177.78vh;
  }
}
.heroHeaderBackgroundVideoControls {
  position: absolute;
  top: 50px;
  right: 50px;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.8));
  z-index: 500;
}
.heroHeaderBackgroundVideoControls button.btn, .heroHeaderBackgroundVideoControls .frn-chat .amplify-tabs button.amplify-button, .frn-chat .amplify-tabs .heroHeaderBackgroundVideoControls button.amplify-button {
  font-size: 2rem;
}
.heroHeaderBackgroundVideoControls button.btn:hover, .heroHeaderBackgroundVideoControls .frn-chat .amplify-tabs button.amplify-button:hover, .frn-chat .amplify-tabs .heroHeaderBackgroundVideoControls button.amplify-button:hover {
  color: #e2e6ea;
}
.heroHeaderBackgroundVideoFullScreen {
  transform: unset;
  position: absolute;
  width: 100vw !important;
  height: 100vh !important;
  top: 0;
  left: 0;
  z-index: 10000;
}
.heroHeaderContent {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1700px;
  padding: 0;
  z-index: 1;
  color: var(--light);
}
.heroHeaderContent .col,
.heroHeaderContent .col-12 {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 576px) {
  .heroHeaderContent {
    position: absolute;
    padding-top: 70px;
    padding-bottom: 25px;
  }
  .heroHeaderContent .col,
  .heroHeaderContent .col-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
.heroHeaderContentBackground {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, cubic-bezier(0.215, 0.61, 0.355, 1), #222);
  backdrop-filter: blur(40px);
  --webkit-backdrop-filter: blur(40px);
  /*
        --webkit-mask-image: linear-gradient(to bottom,transparent,rgba(0,0,0,.068) 3.3%,rgba(0,0,0,.145) 5.9%,rgba(0,0,0,.227) 8.1%,rgba(0,0,0,.313) 10.1%,rgba(0,0,0,.401) 12.1%,rgba(0,0,0,.49) 14.6%,rgba(0,0,0,.578) 17.7%,rgba(0,0,0,.661) 21.8%,rgba(0,0,0,.74) 27.1%,rgba(0,0,0,.812) 33.9%,rgba(0,0,0,.875) 42.4%,rgba(0,0,0,.927) 53%,rgba(0,0,0,.966) 66%,rgba(0,0,0,.991) 81.5%,rgba(0,0,0,.991) 100%);
        mask-image: linear-gradient(to bottom,transparent,rgba(0,0,0,.068) 3.3%,rgba(0,0,0,.145) 5.9%,rgba(0,0,0,.227) 8.1%,rgba(0,0,0,.313) 10.1%,rgba(0,0,0,.401) 12.1%,rgba(0,0,0,.49) 14.6%,rgba(0,0,0,.578) 17.7%,rgba(0,0,0,.661) 21.8%,rgba(0,0,0,.74) 27.1%,rgba(0,0,0,.812) 33.9%,rgba(0,0,0,.875) 42.4%,rgba(0,0,0,.927) 53%,rgba(0,0,0,.966) 66%,rgba(0,0,0,.991) 81.5%,rgba(0,0,0,.991) 100%);
        */
  --webkit-mask-image: linear-gradient(to bottom, transparent, cubic-bezier(0.215, 0.61, 0.355, 1), black);
  mask-image: linear-gradient(to bottom, transparent, cubic-bezier(0.215, 0.61, 0.355, 1), black);
}
.heroHeaderContent .col {
  color: var(--light);
  display: flex;
  flex-direction: column;
}
.heroHeaderContent, .heroHeaderLogo {
  opacity: 1;
  transition: opacity 0.8s ease-out, margin-top 0.8s step-start, z-index 0.8s step-start;
}
.heroHeader.hideOverlays .heroHeaderContent, .heroHeader.hideOverlays .heroHeaderLogo {
  opacity: 0;
  margin-top: -100vh;
  z-index: -5;
  transition: opacity 0.4s ease-out, margin-top 0.4s step-end, z-index 0.4s step-end;
}
.heroHeader .userAvatar {
  width: 100%;
  aspect-ratio: 1/1;
}

.liveVideoHeaderInner {
  background: linear-gradient(0deg, transparent, cubic-bezier(0.215, 0.61, 0.16, 1), #000);
  display: flex;
  align-items: top;
}
.liveVideoHeaderVideo {
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 177.7777777778vh;
  margin: 0 auto;
}
.liveVideoHeaderComments {
  height: 100%;
  max-height: 100vh;
  flex: 0 0 450px;
  margin: 0 0.75rem;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
}
.liveVideoHeaderComments::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.liveVideoHeaderComments::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.no-content {
  background-image: url("https://cdn.foodrevolution.org/members/assets/pbcc/no-content.jpg");
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.facebookCommentsSlideOut {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 100vw;
  z-index: 3000;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: left 0.4s ease-in-out;
}
.facebookCommentsSlideOut > :global(.container-fluid), .facebookCommentsSlideOut > :global(.container-fluid) > :global(.row) {
  --bs-gutter-x: 0;
}
.facebookCommentsSlideOutTrigger {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -100%) rotate(-90deg);
  transform-origin: bottom;
  z-index: 300;
  font-size: 1.5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: left 0.4s ease-in-out, opacity 0.4s ease-in-out;
}
.facebookCommentsSlideOutTriggerHidden {
  left: 100px;
  opacity: 0;
}
.facebookCommentsSlideOutVisible {
  left: 0;
}
.facebookCommentsSlideOutVisible .facebookCommentsSlideOutTrigger {
  left: 8.3333333333%;
}
@media (min-width: 768px) {
  .facebookCommentsSlideOutVisible .facebookCommentsSlideOutTrigger {
    left: 25%;
  }
}