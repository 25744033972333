.audioContainer {
  @include addTrailingMargin;
  audio {
    display: block;
  }
  &:last-of-type {
    @include addTrailingMargin(0.33);
  }
  @include addPadding;
}
