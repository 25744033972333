// .destroyPadding {
//   @include noPadding;
// }

.authorCardContainer {
  box-shadow: none !important;
  background-color: transparent;
  width: 350px;

  .authorTagsBox {
    @include tagsBox;
  }

  .authorCardCardBody {
    display: flex;
    flex-direction: column-reverse;
    @include bp(sm) {
      flex-direction: column;
    }
    .authorInfoBox {
      display: flex;
      .authorImg {
        height: 46px;
        width: 46px;
        margin-right: 24px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .authorTextLines {
        span {
          font-size: 16px;
        }

        .authorName {
        }

        .authorInfo {
          span {
            color: #007bff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

// .bonusPageTitle {
//   @include pageTitle;
//   @include addLeadingMargin;
//   @include addTrailingMargin;
//   @include addPadding;
// }

// .bonusesList {
//   @include addTrailingMargin(.3);
// }
