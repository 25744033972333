.carouselContainer::-webkit-scrollbar {
  display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */


.carouselContainer {
  margin: 0;
  position: relative;
  ul{
    padding: 0;
  }
  &.row{
    margin-left: -15px;
    margin-right: -15px;
  }
  @include media-breakpoint-down(xs){
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: -30px;
    margin-right: -30px;
    li:first{
      padding-left: 15px;
    }
    .col, .col-auto{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .carouselNav {
    list-style: none;
    margin: 0;
    .btn{
      position: absolute;
      top: 0;
      height: 100%;
      width: 40px;
      font-size: 40px;
      svg{
        transform: 'scale(1, 1.62)'
      }
    }
    &-next{
      right: 0;
    }
    &-prev{
      left: 0;
    }
    .btn{ 
      color: var(--light);
      transition: 250ms background-color linear,250ms color linear,250ms opacity linear;
      &:disabled{
        opacity: 0;
      }
    }
  }

  &:hover .carouselNav {
    .btn:not(:disabled){
      opacity: 1;
    }
  }
  .btn:focus{
    box-shadow: none;
  }
  .col, .col-auto{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  ul{
    display: grid;
    grid-auto-flow: column;
    list-style: none;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scrollbar-width: none;
    overscroll-behavior-x: none;
    grid-auto-columns: 270px;

    @include media-breakpoint-up(sm){
      -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
      grid-auto-columns: calc((100% - 2 * 20px)/ 3);
    }

    @include media-breakpoint-up(lg){
      grid-auto-columns: calc((100% - 3 * 20px)/ 4);
    }

    @include media-breakpoint-up(xl){
      grid-auto-columns: calc((100% - 4 * 20px)/ 5);
    }

    grid-template-rows: repeat(1,-webkit-max-content);
    grid-template-rows: repeat(1,max-content);
    grid-column-gap: 20px;
    user-select: none;
    &::-webkit-scrollbar {
      display: none;
    }
    li{
      scroll-snap-align: start;
      cursor: pointer;
    }
  }
  .description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3; 
    -webkit-box-orient: vertical;
  }
}
.card-img, .card-img-top {
  aspect-ratio: 1.5;
  object-fit: cover;
}
}

.carousel-hero{
  .carouselContainer {
    ul{
      @include media-breakpoint-up(sm){
        -webkit-scroll-snap-type: x mandatory;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        grid-auto-columns: calc((100% - 2 * 20px)/ 3);
      }
      @include media-breakpoint-up(xl){
        grid-auto-columns: calc((100% - 3 * 20px)/ 4);
      }
    }
  }
}

.carousel-light{
  .carouselNav {
    .btn{ 
      color: var(--dark);
    }
  }
}

