.iconFontSize {
  font-size: 20px;
}

.fullScreenModal {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  padding: 6px;

  @include bp(lg) {
    width: 80% !important;
    height: 80% !important;
    max-width: 80% !important;
    margin-left: 10%;
    margin-top: 5%;
    padding: 6px;
  }
}

.moduleTitle {
  @include font-bold;
}

.col {
  color: $FRNGreyDark;
}

.lessonHeading {
  font-size: 22px;
  font-weight: $headings-font-weight;
  color: $FRNGreyDark;
}

.lessonTitle {
  // font-weight:$headings-font-weight;
  font-size: $font-size-base;
  padding-left: 5px;

  @include bp(lg) {
    padding-left: 15px;
  }
}

.lessonLocked .lessonTitle {
  color: white;
  opacity: 0.8;
}

.lessonImage {
  position: relative;

  @include bp(lg) {
    padding-right: 15px;
  }
}

.lessonImage img {
  width: 80px;

  @include bp(lg) {
    width: 100px;
  }
}

.lessonImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px;
  padding: 0px;
  height: 100px;
  width: 100px;

  border: thin solid red;
}

.modulePageHeader {
  // z-index:900;
  height: 60px; //subtract 2 for the shadow

  @include bp(lg) {
    // margin-left:-220px;
    // padding-left:235px;
    // padding-right:15px;
  }
}

.moduleHeader h2,
h4 {
  margin-top: 1em;
  margin-bottom: 1em;
}
