.broadcastsPlusContainer {
  @include noPadding;
  @include addLeadingMargin;
  @include addTrailingMargin;
}

.broadcastsPlusList {
  @include addTrailingMargin(2);
}

.broadcastsPlusListTitle {
  @include pageTitle;
  @include addTrailingMargin;
  @include addPadding;
}

.subTitle {
  @include subTitle;
}
