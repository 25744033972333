@use "sass:color";
//Brand Colors
$FRNGreen: #048d22;
$FRNOrange: #ff9400;
$FRNDarkOrange: #fb6d25;
$FRNPurple: #7d00b5;
$FRNDarkBlueGray: #334850;
$FRNMediumBlueGray: #4d6f7b;
$FRNLightBlueGray: #eceff4;
$FRNLightGray: #f3f4f5;

//deprecated
$FRNLightGreyBackground: #dce0e2;
$FRNGreyBackground: #eceff1;
$FRNMediumGreyBackground: #7d7e80;
$FRNDarkBlueGreyBackground: #546e7a;
$FRNDarkGreyBackground: #1e2629;
$FRNGreyLighter: #e8e8e8;
$FRNRed: #a94442;
$FRNTomatoRed: #d11f39;
$FRNRedLight: #f2dede;
$black: #222;
$white: #ffffff;
$transparent: rgba($white, 0);

$true-black: #000;
$dark-black: #191919;
$black: #222;
$light-gray: #e8e8e8;
$transparent: rgba($white, 0);
$cta-border: #cc6900;
$dark-gray: #444;
$medium-gray: #9b9b9b;

$facebook: #4267b2;
$facebook-hover: #365899;
$twitter: #00aced;
$twitter-hover: #0084b4;
$pinterest: #cb2027;
$instagram: #e03566;
$youtube: #f00;
$youtube-hover: color.adjust(#f00, $lightness: -5%);

$alert-warning-bg: #fcf8e3;
$alert-warning-color: #8a6d3b;
$alert-warning-border: #faebcc;
$alert-info-color: #31708f;
$alert-info-bg: #d9edf7;
$alert-info-border: #bce8f1;
$alert-danger-color: #a94442;
$alert-danger-bg: #f2dede;
$alert-danger-border: #ebccd1;
$alert-success-color: #3c763d;
$alert-success-bg: #dff0d8;
$alert-success-border: #d6e9c6;

//brand-01

//brand-02

//brand-03
//#630090;
$FRNPurple-02: #7d00b5;

// SideBar - Nav extension
$FRNDarkPurple: #440062;
//brand-04
$FRNBlue: #023e8e;
$blue-01: $FRNBlue;

// WLC Nav items
$blue-02: #007bff;
//brand-05
$FRNLightBlue: #72c9ed;
//brand-06
$FRNPink: #e91e63;
//brand-07
$FRNGold: #f8bb1c;

$FRNOval: #eaf4f2;

//ui-01
$white-01: #ffffff;
//ui-02
$white-02: #f5f7fa;
//ui-03
$white-03: #dfe3e6;
//ui-04
$white-04: #8897a2;
//ui-05
$white-05: #5a6872;
//ui-06
$white-06: #37474f;

$white-purple: #fafaff;

// These two are in the sidebar
$grey-05: #302f3a;
$grey-06: #23222e;

$grey-blue: #bacad5;
$grey-blue-dark: #8493a8;
$primary: $FRNGreen;
$secondary: $FRNOrange;
$purple: $FRNPurple;

$theme-colors: (
  'primary': $FRNGreen,
  'secondary': $FRNOrange,
  'purple': $FRNPurple,
  'dark': #222,
  'blue-gray': $FRNMediumBlueGray
);

$yiq-contrasted-threshold: 175; //Makes it so that text color is white on FRNOrange Backgrounds

// Tag
$tag-blue: #17a2b8;

//text-01
$text-01: #37474f;
//text-02
$text-02: #222;
//text-03
$text-03: #cdd1d4;
//inverse-01
$inverse-01: #ffffff;
//inverse-02
$inverse-02: #272d33;
//field-01
$field-01: #fbfbfb;
//field-02
$field-02: #ffffff;

$gradient-1-start: #ca3058;
$gradient-1-end: #ff9a48;

$main-three-way-box-shadow: 0 1px 4px 0 rgba(186, 202, 213, 0.63);
$main-three-way-box-shadow-darker: 0 1px 4px 0 rgba(186, 202, 213, 1);

//***=====deprecated colors====***
$FRNLightOrange: #ffcc80; //Checkout BonusOffer
// $FRNGreyBackground: #ECEFF1; //Upsell
// $FRNLightGreyBackground: #DCE0E2; //Upsell
// $FRNDarkGreyBackground: #1e2629;
// $FRNDarkGreen: #4c823c;
// $FRNMediumGreyBackground: #7d7e80;
// $FRNDarkBlueGreyBackground: #546E7A;
$FRNLightGreen: #f7fff1; // Checkout BonusOffer GiftRecipient
$FRNLightGreenBackground: #f4fbf3;
$FRNGrey: #444; // SessionsList
$FRNGreyLight: #4d6f7b;
$FRNGreyDark: #222;
// $FRNGreyLighter:lightgrey;
// $FRNRed:#a94442;
// $FRNTomatoRed:#d11f39;
// $FRNRedLight:#f2dede;
// $black: #000;
// $dark-gray: #333;

$text-color-body: $text-02;
$text-color-body-dark: $FRNGreyDark;
$text-color-body-muted: #868e96;

$text-color-heading: $FRNGreyDark;
$text-color-heading-light: $white;
$text-color-heading-muted: #868e96;

$sm-min: 768px;
$md-min: 992px;
$lg-min: 1200px;

$xs-max: ($sm-min - 1) !default;
$sm-max: ($md-min - 1) !default;
$md-max: ($lg-min - 1) !default;

$font-size-base: 1.6rem;

$font-size-2xsmall: $font-size-base * 0.5;
$font-size-xsmall: $font-size-base * 0.625;
$font-size-small: $font-size-base * 0.75;
$font-size-medium: $font-size-base * 0.875;
$font-size-lg-md: $font-size-base * 1;
$font-size-regular: $font-size-base * 1;
$font-size-large: $font-size-base * 1.125;
$font-size-lg-xl: $font-size-base * 1.1875;
$font-size-xlarge: $font-size-base * 1.25;
$font-size-2xlarge: $font-size-base * 1.375;
$font-size-4xl: $font-size-base * 1.625;
$font-size-5xl: $font-size-base * 1.75;
$font-size-6xl: $font-size-base * 1.875;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1;

$font-size-16: $font-size-base * 0.8 !default;

$headings-color: $text-color-heading;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$headings-margin-top: 1.5em;
$headings-margin-bottom: 0.75em;

$font-size-h1: $h1-font-size;
$font-size-h2: $h2-font-size;
$font-size-h3: $h3-font-size;
$font-size-h4: $h4-font-size;
$font-size-h5: $h5-font-size;
$font-size-h6: $h6-font-size;

$breadcrumb-bg: transparent;
$breadcrumb-divider: '>';
$breadcrumb-padding-x: 0;

$card-cap-bg: $white;

/*
$font-size-h5:                $font-size-base;

$font-size-h4:                ceil(($font-size-base * 1.333));
$font-size-lg-md:             ceil(($font-size-base * 1.555));
$font-size-h3:                ceil(($font-size-base * 1.777));
$font-size-lg-xl:             ceil(($font-size-base * 1.999));
$font-size-h2:                ceil(($font-size-base * 2.369));
$font-size-h1:                ceil(($font-size-base * 3.157));
$font-size-4xl:               ceil(($font-size-base * 3.989));
$font-size-5xl:               ceil(($font-size-base * 4.978));
$font-size-6xl:               ceil(($font-size-base * 5.989));
*/

$generic-left-margin: 26px;
// SessionsList
$consistent-trailing-bottom-margin: 18px;
$consistent-leading-top-margin: 32px;
// used in SessionsList
$main-link-line-height: 36px;

$consistent-span-text-size: 12px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  2xl: 1700px
);

@mixin input {
  @include addTrailingMargin(2);
  background-color: $white-03;
}

@mixin standardInput {
  line-height: 2.5;
}

@mixin profileInput {
  @include input;
}

@mixin profileDataItem {
  padding-left: 16px;
  span {
    line-height: 2.5;
  }
}

@mixin profileInputLabel {
}
